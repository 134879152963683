import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useQueryParams} from "../../hooks/useQueryParams";
import GenericTableScreen from "../GenericTableScreen/GenericTableScreen";
import {useHeadersFromLocalStorage} from "../../hooks/useHeadersFromLocalStorage";
import {AppContext, RefreshContext} from "../../services";
import {useLoader} from "../../components/Loader/LoaderProvider";

type Props<T> = {
    storageKey: AllowedValuesKey;
    columns: TableHeaderNormalType<T>[];
    fetchFunction: (searchParams: string, companyId: number) => Promise<any>;
    startDownloadFunction?: (searchParams: string, companyId: number) => Promise<DownloadResponse>;
    addModalType?: ModalContentsTypeType;
    itemKey: keyof T;
    title: string;
    renderers?: TableRenderers<T>;
    refreshKey?: string;
    tableNoResults?: React.ReactNode;
};

const GenericTableScreenWrapper = <T, >({
                                            storageKey,
                                            columns,
                                            fetchFunction,
                                            startDownloadFunction,
                                            addModalType,
                                            itemKey,
                                            title,
                                            renderers,
                                            refreshKey,
                                            tableNoResults,
                                        }: Props<T>) => {
    const {currentCompanyId} = useContext(AppContext);
    const {startLoading, stopLoading} = useLoader();
    const {registerRefreshKey, unregisterRefreshKey} = useContext(RefreshContext);
    const [items, setItems] = useState<T[] | null | undefined>(undefined);
    const [nbPages, setNbPages] = useState(0);

    const currentQueryString = useQueryParams<T>(columns);
    const [savedQueryString, setSavedQueryString] = useState(currentQueryString);
    const [headers, setHeaders] = useHeadersFromLocalStorage<T>(storageKey, columns);

    const fetchItemsFunc = useCallback(async () => {
        startLoading();

        try {
            const data = await fetchFunction(currentQueryString, currentCompanyId!);
            setItems(data.items);
            setNbPages(data.nbPages);
        } catch (error) {
            setItems(null);
            setNbPages(0);
        }

        setSavedQueryString(currentQueryString);
        stopLoading();
    }, [currentQueryString, fetchFunction, currentCompanyId, startLoading, stopLoading]);

    useEffect(
        () => {
            const refreshFunc = async () => {
                await fetchItemsFunc();
            };

            //Do initial load for the current page
            (async () => {
                await refreshFunc();
            })();

            if (refreshKey) {
                //So we can refresh this page from other places in the app
                registerRefreshKey(refreshKey, refreshFunc);


                return () => {
                    unregisterRefreshKey(refreshKey, refreshFunc);
                };
            }
        },
        [fetchItemsFunc, refreshKey, registerRefreshKey, unregisterRefreshKey]
    );

    let rows: TableRowType<T>[] | null | undefined = undefined;
    if (items === null) {
        rows = null;
    }

    if (items) {
        rows = items.map(item => ({
            ...Object.keys(item).reduce((acc, key) => {
                // @ts-ignore
                acc[key] = item[key as keyof T];

                if (renderers) {
                    const renderFunc: TableRenderFunc<T> | undefined = renderers[key as keyof T];
                    if (renderFunc) {
                        // @ts-ignore
                        acc[key] = renderFunc(item);
                    }
                }

                return acc;
            }, {} as TableRowType<T>),
            key: String(item[itemKey]),
        }));
    }

    return (
        <GenericTableScreen
            title={title}
            headers={headers}
            setHeaders={setHeaders}
            allHeaders={columns}
            rows={rows}
            queryString={savedQueryString}
            nbPages={nbPages}
            storageKey={storageKey}
            startDownloadFunction={startDownloadFunction}
            addModalType={addModalType}
            tableNoResults={tableNoResults}
        />
    );
};

export default GenericTableScreenWrapper;
