import React, {useCallback, useState} from 'react';
import styles from './FileUpload.module.css';
import {useDropzone} from "react-dropzone";
import prettyBytes from 'pretty-bytes';

export interface Props {
    file?: File;
    setFile: (file: File) => void;
}

const FileUpload = ({
                        file,
                        setFile,
                    }: Props) => {
    const [error, setError] = useState('');

    const onDrop = useCallback((acceptedFiles, fileRejections) => {

        if (acceptedFiles[0]) {
            setFile(acceptedFiles[0])
            setError('');
        }

        if (fileRejections[0]) {
            const suffix = fileRejections[0].file.path.slice(-5).toLowerCase();
            if (suffix === '.xlsx') {
                setError('An error occurred. Refresh this page or try again later.');
            } else {
                setError('Only .xlsx files are supported');
            }
        }
    }, [setFile])

    const {getRootProps, getInputProps} = useDropzone({
        onDrop: onDrop,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
        multiple: false,
    });

    return (
        <div className={styles.fileWrapper} {...getRootProps()}>
            <input {...getInputProps()} />
            {file ? (
                <div>
                    <div>{file!.name}</div>
                    <div className={styles.bytes}>{prettyBytes(file!.size)}</div>
                </div>
            ) : (
                <span>Drop a file here or click to select a file</span>
            )}
            <div className={styles.error}>{error}</div>
        </div>
    );
};

export default FileUpload;
