import React, {useContext} from 'react';
import MenuLeft from "./MenuLeft";
import {AppContext} from "../../services";

const MenuLeftWrapper = () => {
    const {companies, currentCompanyId} = useContext(AppContext);
    const company = companies.find(company => company.id === currentCompanyId);
    const smsPage: boolean = Boolean(company?.smsSenders?.length);
    const paylinksPage: boolean = Boolean(company?.paylinkSenders?.length);

    return (
        <MenuLeft smsPage={smsPage} paylinksPage={paylinksPage}/>
    );
};

export default MenuLeftWrapper;
