import React from 'react';
import {H1} from "../../components/titles/titles";
import styles from "./TransactionScreen.module.css";
import globalStyles from "../../styles/styles.module.css";
import FieldsPane, {FieldsCol} from "../../components/FieldsPane/FieldsPane";
import Field from "../../components/Field/Field";
import ItemNoResults from "../../components/ItemNoResults/ItemNoResults";
import PaymentStatusAmount from "../../components/PaymentStatusAmount/PaymentStatusAmount";
import {Link} from "react-router-dom";

type Props = {
    transaction: TransactionType | null | undefined;
};

const TransactionScreen = ({
                               transaction,
                           }: Props) => {
    return (
        <div>
            <div className={styles.titleWrapper}>
                <H1>Transaction</H1>
            </div>
            {transaction && <TransactionScreenContents transaction={transaction}/>}
            {transaction === null && <ItemNoResults/>}
        </div>
    );
};

type TransactionScreenContentsProps = {
    transaction: TransactionType;
};

const TransactionScreenContents = ({transaction}: TransactionScreenContentsProps) => {
    const {
        amount,
        communication,
        status,
        transactionDate,
        paymentMethod,
        beneficiaryIban,
        customerId,
        transactionId,
        channel,
        customerIban,
        customerBic,
        documentId,
    } = transaction;

    return (
        <>
            <div className={styles.primaryFieldsWrapper}>
                <FieldsPane>
                    <FieldsCol>
                        <Field label="Communication" children={communication}/>
                        <Field label="Payment method" children={paymentMethod}/>
                        <Field label="Beneficiary IBAN" children={beneficiaryIban}/>
                    </FieldsCol>
                    <FieldsCol>
                        <PaymentStatusAmount status={status} amount={amount}/>
                        <div className={globalStyles.detailsRightWrapper}>
                            <div>Transaction date: {transactionDate}</div>
                            <div><Link to={`/transactions/document?id=${encodeURIComponent(documentId)}`}>Document</Link></div>
                        </div>
                    </FieldsCol>
                </FieldsPane>
            </div>
            <FieldsPane>
                <FieldsCol>
                    <Field label="Customer Id" children={customerId}/>
                    <Field label="Customer IBAN" children={customerIban}/>
                </FieldsCol>
                <FieldsCol>
                    <Field label="Channel" children={channel}/>
                    <Field label="Customer BIC" children={customerBic}/>
                </FieldsCol>
            </FieldsPane>
            <div className={globalStyles.primaryID}>
                <span>POM Transaction Id: </span>
                {transactionId}
            </div>
        </>
    );
};

export default TransactionScreen;
