import React from 'react';
import styles from './Button.module.css';
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

export interface ButtonProps {
    backgroundColor?: 'green' | 'red' | 'transparent' | 'white';
    label: string;
    onClick?: () => void;
    disabled?: boolean;
    loading?: boolean;
}

const Button = ({
                    backgroundColor = 'green',
                    label,
                    onClick,
                    disabled,
                    loading,
                }: ButtonProps) => {
    return (
        <input
            className={classNames({
                [styles.button]: true,
                [styles.buttonDisabled]: disabled || loading,
                [styles.buttonGreen]: backgroundColor === 'green',
                [styles.buttonRed]: backgroundColor === 'red',
                [styles.buttonWhite]: backgroundColor === 'white',
                [styles.buttonTransparent]: backgroundColor === 'transparent',
            })}
            type="submit"
            onClick={onClick}
            value={label}
        />
    );
};

export default Button;
