import React from 'react';
import {useWindowSize} from "../../hooks/useWindowSize";
import TooSmallScreenWrapper from "../../screens/TooSmallScreen/TooSmallScreenWrapper";

type Props = {
    children: React.ReactNode;
};

const SizeCheck = ({children}: Props) => {
    const {width} = useWindowSize();

    if (!width) {
        return null;
    }

    if (width < 768) {
        return <TooSmallScreenWrapper/>;
    }

    return <>{children}</>;
};

export default SizeCheck;
