import React, {useContext, useReducer} from 'react';
import {useHistory} from "react-router-dom";
import {useQueryParts} from "../../hooks/useQueryParts";
import SidebarFilterDate from "./SidebarFilterDate";
import {AppContext, SidebarContext} from "../../services";
import SidebarForm from "../SidebarForm/SidebarForm";
import SidebarFilterText from "./SidebarFilterText";
import SidebarFilterNumber from "./SidebarFilterNumber";
import SidebarFilterCheckboxes from "./SidebarFilterCheckboxes";
import SidebarFilterContainer from "./SidebarFilterContainer";

type Props<T> = {
    visibleFilters: TableHeaderNormalType<T>[];
    allowedValuesKey: AllowedValuesKey;
};

export type FiltersType = {
    [index: string]: string | string[] | undefined;
};

export type FiltersReducerAction = {
    type: 'setValue';
    key: string;
    value: string | string[] | undefined;
};

export const operatorLabels: { [index in OperatorType]: string; } = {
    equals: 'Equals',
    contains: 'Contains',
};

const SidebarFilters = <T, >({
                                 visibleFilters,
                                 allowedValuesKey,
                             }: Props<T>) => {
    const {allowedValues} = useContext(AppContext);
    const {hideSidebar} = useContext(SidebarContext);
    const history = useHistory();
    const {parsedQuery, buildUrl} = useQueryParts();

    const reducer = (filters: FiltersType, action: FiltersReducerAction): FiltersType => {
        if (action.type === 'setValue') {
            return {
                ...filters,
                [action.key]: action.value,
            };
        }

        return filters;
    };
    const [filters, dispatch] = useReducer(reducer, parsedQuery as FiltersType);

    const handleSubmit = () => {
        const filteredValues = {...filters};

        visibleFilters.filter(filter => filter.filterable).forEach(filter => {
            const filterValue = filteredValues[`${filter.key}`];
            const filterValueTo = filteredValues[`${filter.key}_to`];
            if (filterValue || filterValueTo) {
                if (filter.filterable === 'number') {
                    if (filterValue) {
                        filteredValues[`${filter.key}`] = String(parseFloat(filterValue as string));
                    }
                    if (filterValueTo) {
                        filteredValues[`${filter.key}_to`] = String(parseFloat(filterValueTo as string));
                    }
                }
            } else {
                filteredValues[`${filter.key}`] = undefined;
                filteredValues[`${filter.key}_to`] = undefined;
                filteredValues[`${filter.key}_op`] = undefined;
            }
        });

        history.push(buildUrl(filteredValues));
        hideSidebar();
    };

    return (
        <SidebarForm onSubmit={handleSubmit}>
            {visibleFilters.map((visibleFilter, i) => {
                let element = null;

                const filterable = visibleFilter.filterable;
                switch (filterable) {
                    case "text":
                        element = (
                            <SidebarFilterText
                                headerConfig={visibleFilter}
                                activeFilters={filters}
                                dispatch={dispatch}
                                autoFocus={i === 0}
                            />
                        );
                        break;
                    case "number":
                        element = (
                            <SidebarFilterNumber
                                headerConfig={visibleFilter}
                                activeFilters={filters}
                                dispatch={dispatch}
                                autoFocus={i === 0}
                            />
                        );
                        break;
                    case "date":
                        element = (
                            <SidebarFilterDate
                                headerConfig={visibleFilter}
                                activeFilters={filters}
                                dispatch={dispatch}
                            />
                        );
                        break;
                    case "checkboxes":
                        element = (
                            <SidebarFilterCheckboxes
                                headerConfig={visibleFilter}
                                options={allowedValues[allowedValuesKey][visibleFilter.key as string] || []}
                                activeFilters={filters}
                                dispatch={dispatch}
                            />
                        );
                        break;
                    case false:
                        //Don't render a filter
                        return null;
                    default:
                        //Union Exhaustiveness checking (https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html)
                        return assertNever(filterable);
                }

                return (
                    <SidebarFilterContainer key={visibleFilter.key as string} title={`Filter on ${visibleFilter.title}`}>
                        {element}
                    </SidebarFilterContainer>
                );
            })}
        </SidebarForm>
    );
};

const assertNever = (x: never): null => {
    return null;
};

export default SidebarFilters;
