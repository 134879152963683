import React from 'react';
import {fetchTransactions, formatAmount, isTableHeaderNormalType, startTransactionsDownload} from "../../services";
import {transactionColumns} from "../../components/Table/columns";
import GenericTableScreenWrapper from "../GenericTableScreen/GenericTableScreenWrapper";
import GenericTablePrimaryLink from "../../components/GenericTablePrimaryLink/GenericTablePrimaryLink";

const TransactionsScreenWrapper = () => {
    const renderers: TableRenderers<TransactionTableType> = {
        transactionId: (item) => <GenericTablePrimaryLink to={`/transactions/${item.transactionId}`}/>,
        amount: (item) => formatAmount(item.amount),
    };

    return (
        <GenericTableScreenWrapper
            title="Transactions"
            columns={Object.values(transactionColumns).filter(isTableHeaderNormalType)}
            fetchFunction={fetchTransactions}
            startDownloadFunction={startTransactionsDownload}
            itemKey="transactionId"
            storageKey="transactions"
            renderers={renderers}
        />
    );
};

export default TransactionsScreenWrapper;
