import React from 'react';
import styles from "./GenericTablePrimaryLink.module.css";
import {Link} from "react-router-dom";
import classNames from "classnames";

type Props = {
    to: string;
    tag?: 'a';
    icon?: 'dots' | 'download';
    onClick?: (event: React.MouseEvent) => void;
};

const GenericTablePrimaryLink = ({
                                     to,
                                     tag,
                                     icon = 'dots',
                                     onClick,
                                 }: Props) => {
    if (tag === 'a') {
        return (
            <a href={to} className={styles.iconWrapper} onClick={onClick}>
                <div className={classNames(styles.icon, {
                    [styles.download]: icon === 'download',
                    [styles.dots]: icon === 'dots',
                })}/>
            </a>
        );
    } else {
        return (
            <Link to={to} className={styles.iconWrapper}>
                <div className={classNames(styles.icon, {
                    [styles.download]: icon === 'download',
                    [styles.dots]: icon === 'dots',
                })}/>
            </Link>
        );
    }
};

export default GenericTablePrimaryLink;
