import React from 'react';
import styles from './TextfieldInline.module.css';

export interface TextfieldProps {
    value: string;
    setValue: (value: string) => void;
    type?: 'text' | 'number';
}

const TextfieldInline = ({
                             value = '',
                             setValue,
                             type = 'text',
                         }: TextfieldProps) => {
    return (
        <input
            className={styles.textfield}
            type={type}
            value={value}
            onChange={e => setValue(e.target.value)}
        />
    );
};

export default TextfieldInline;
