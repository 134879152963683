import React from 'react';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip} from 'recharts';
import styles from "./PomBarChart.module.css";
import CustomTooltip from "../Charts/CustomTooltip/CustomTooltip";

type Props = {
    data: BarChartDataType[];
};

const PomBarChart = ({data}: Props) => {
    return (
        <div className={styles.chartWrapper}>
            {data.length > 0 && (
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart width={150} height={40} data={data}>
                        <CartesianGrid
                            stroke="#d9e8e2"
                            vertical={false}
                        />

                        <XAxis
                            dataKey="label"
                            tickLine={false}
                            stroke="#00683E"
                            strokeWidth={2}
                        />

                        <YAxis
                            axisLine={false}
                            tickLine={false}
                            allowDecimals={false}
                            stroke="#00683E"
                        />

                        <Tooltip
                            cursor={false}
                            separator=": "
                            content={({active, payload}) => {
                                if (active && payload && payload.length) {
                                    return <CustomTooltip>{payload[0].value}</CustomTooltip>;
                                }

                                return null;
                            }}
                        />

                        <Bar
                            dataKey="value"
                            fill="#90C549"
                            isAnimationActive={false}
                            radius={[3, 3, 0, 0]}
                        />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};

export default PomBarChart;
