import React from 'react';
import styles from './Checkboxes.module.css';
import classNamesBind from 'classnames/bind';
import CheckboxesNormalWrapper from "./CheckboxesNormalWrapper";
import CheckboxesDraggableWrapper from "./CheckboxesDraggableWrapper";
import {DropResult} from "react-beautiful-dnd";
import Checkbox from "../Checkbox/Checkbox";

const classNames = classNamesBind.bind(styles);

export type CheckboxOption = {
    key: string;
    value: string;
    disabled?: boolean;
};

export interface CheckboxesProps {
    options: CheckboxOption[];
    value: string[];
    setValue: (value: string[]) => void;
    sortable?: boolean;
    setOptions?: (options: CheckboxOption[]) => void;
}

const Checkboxes = ({
                        options,
                        value,
                        setValue,
                        sortable,
                        setOptions,
                    }: CheckboxesProps) => {

    const items = options.map(option => {
        const isChecked = value.includes(option.key);

        const handleClick = () => {
            if (option.disabled) {
                return;
            }

            if (isChecked) {
                setValue(value.filter(x => x !== option.key));
            } else {
                setValue([...value, option.key]);
            }
        };

        return {
            item: option,
            node: (
                <div
                    className={classNames({
                        [styles.item]: true,
                        [styles.itemChecked]: isChecked,
                    })}
                    key={option.key}
                    onClick={handleClick}
                >
                    <Checkbox
                        isChecked={isChecked}
                        isDisabled={option.disabled}
                    />
                    <span className={styles.itemContent}>{option.value}</span>
                    {sortable && (
                        <div
                            className={classNames(styles.icon, styles.iconSortable)}
                        />
                    )}
                </div>
            ),
        };
    });

    if (sortable) {
        const handleDragEnd = (result: DropResult) => {
            if (!result.destination) {
                return;
            }

            const newOptions = [...options];
            const [removed] = newOptions.splice(result.source.index, 1);
            newOptions.splice(result.destination.index, 0, removed);

            if (setOptions) {
                setOptions(newOptions);
            }
        };

        return (
            <CheckboxesDraggableWrapper items={items} onDragEnd={handleDragEnd}/>
        );
    } else {
        return (
            <CheckboxesNormalWrapper items={items}/>
        );
    }
};

export default Checkboxes;
