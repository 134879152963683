import React from 'react';
import {H2} from "../titles/titles";
import styles from "./TableError.module.css";

const TableError = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <H2>An error occurred</H2>
            </div>
            <div className={styles.description}>
                Refresh this page or try again later.
            </div>
        </div>
    );
};

export default TableError;
