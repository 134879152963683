import React, {useEffect, useState} from 'react';
import styles from './Pager.module.css';
import classNamesBind from 'classnames/bind';
import {useSavedQueryParts} from "../../hooks/useQueryParts";
import {Link} from "react-router-dom";

const classNames = classNamesBind.bind(styles);

export interface PagerProps {
    nbPages: number;
}

const Pager = ({
                   nbPages,
               }: PagerProps) => {
    const [window, setWindow] = useState<number[]>([]);

    const {parsedQuery, buildUrl} = useSavedQueryParts();
    const page = parseInt(String(parsedQuery.page)) || 0; //Zero-based index

    useEffect(
        () => {
            if (page < 0 || nbPages <= 0) {
                return;
            }

            //Start with window so it's large enough
            //to have current page as first or last place
            let window = [
                page - 6,
                page - 5,
                page - 4,
                page - 3,
                page - 2,
                page - 1,
                page,
                page + 1,
                page + 2,
                page + 3,
                page + 4,
                page + 5,
                page + 6,
            ];

            //Trim invalid leading elements
            while (window[0] < 0) {
                window.shift();
            }

            //Trim invalid trailing elements
            while (window[window.length - 1] > nbPages - 1) {
                window.pop();
            }

            //Trim size of window
            //Always trim on the side that is furthest
            //from current page, so current page stays
            //in the middle of the window
            //Current page will stay on the sides when
            //we're on the first or last pages of the window.
            const maxPages = Math.min(nbPages, 7);
            while (window.length > maxPages) {
                const idx = window.indexOf(page);
                if (idx >= window.length - idx) {
                    window.shift();
                } else {
                    window.pop();
                }
            }

            setWindow(window);
        },
        [page, nbPages]
    );

    const goLeftDisabled = page === window[0];
    const goRightDisabled = page === window[window.length - 1];

    return (
        <div className={styles.wrapper}>

            {/* Go to first */}
            <Link
                className={classNames({
                    [styles.icon]: true,
                    [styles.iconDoubleLeft]: true,
                    [styles.iconDisabled]: goLeftDisabled,
                })}
                to={buildUrl({page: undefined})} //Backend expects zero-based
            />

            {/* Go to previous */}
            <Link
                className={classNames({
                    [styles.icon]: true,
                    [styles.iconLeft]: true,
                    [styles.iconDisabled]: goLeftDisabled,
                })}
                to={buildUrl({page: page - 1 > 0 ? page - 1 : undefined})} //Backend expects zero-based
            />

            {/* First and ... : Only show when there are more than 7 pages */}
            {(window.length >= 7) && (
                <>
                    {/* Go to first */}
                    <Link
                        children={1}
                        className={classNames({
                            [styles.number]: true,
                            [styles.numberActive]: page === 0,
                        })}
                        to={buildUrl({page: undefined})} //Backend expects zero-based
                    />

                    {/* Go to second OR ... */}
                    <Link
                        children={window[1] === 1 ? 2 : '...'}
                        className={classNames({
                            [styles.number]: true,
                            [styles.numberActive]: page === 1,
                            [styles.numberDisabled]: window[1] !== 1,
                        })}
                        to={buildUrl({page: 1})} //Backend expects zero-based
                    />
                </>
            )}

            {/* If window has more than 7 pages, trim ends that are printed above/below */}
            {(window.length >= 7 ? window.slice(2, -2) : window).map(x => (
                <Link
                    key={x}
                    children={x + 1}
                    className={classNames({
                        [styles.number]: true,
                        [styles.numberActive]: page === x,
                    })}
                    to={buildUrl({page: x > 0 ? x : undefined})} //Backend expects zero-based
                />
            ))}

            {/* ... and last: Only show when there are more than 7 pages */}
            {(window.length >= 7) && (
                <>
                    {/* Go to second to last OR ... */}
                    <Link
                        children={window[window.length - 2] === nbPages - 2 ? nbPages - 1 : '...'}
                        className={classNames({
                            [styles.number]: true,
                            [styles.numberActive]: page === nbPages - 2,
                            [styles.numberDisabled]: window[window.length - 2] !== nbPages - 2,
                        })}
                        to={buildUrl({page: nbPages - 2})} //Backend expects zero-based
                    />

                    {/* Go to last */}
                    <Link
                        children={nbPages}
                        className={classNames({
                            [styles.number]: true,
                            [styles.numberActive]: page === nbPages - 1,
                        })}
                        to={buildUrl({page: nbPages - 1})} //Backend expects zero-based
                    />
                </>
            )}

            {/* Go to next */}
            <Link
                className={classNames({
                    [styles.icon]: true,
                    [styles.iconRight]: true,
                    [styles.iconDisabled]: goRightDisabled,
                })}
                to={buildUrl({page: page + 1 < nbPages ? page + 1 : nbPages - 1})} //Backend expects zero-based
            />

            {/* Go to last */}
            <Link
                className={classNames({
                    [styles.icon]: true,
                    [styles.iconDoubleRight]: true,
                    [styles.iconDisabled]: goRightDisabled,
                })}
                to={buildUrl({page: nbPages - 1})} //Backend expects zero-based
            />
        </div>
    );
};

export default Pager;
