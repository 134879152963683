import React from 'react';
import TooSmallScreen from "./TooSmallScreen";

const TooSmallScreenWrapper = () => {
    return (
        <TooSmallScreen/>
    );
};

export default TooSmallScreenWrapper;
