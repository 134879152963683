import React, {useEffect, useRef} from 'react';
import styles from './Table.module.css';
import TableHeader from "../TableHeader/TableHeader";
import TableRow from "../TableRow/TableRow";
import Tooltip from "../Tooltip/Tooltip";
import ReactTooltip from "react-tooltip";

export interface TableProps<T> {
    headers: TableHeaderNormalType<T>[];
    rows: TableRowType<T>[];
    onFilterClick: (header: TableHeaderNormalType<T>) => void;
}

const Table = <T, >({
                        headers,
                        rows,
                        onFilterClick,
                    }: TableProps<T>) => {
    const headerRef = useRef<HTMLDivElement>(null);
    const rowsRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        headerRef.current!.scrollLeft = rowsRef.current!.scrollLeft;
    };

    const reactTooltipRebindKey = JSON.stringify(rows.map(row => row.key));

    useEffect(
        () => {
            ReactTooltip.rebuild();
        },
        [reactTooltipRebindKey]
    );

    return (
        <div>
            <TableHeader
                headers={headers}
                onFilterClick={onFilterClick}
                scrollRef={headerRef}
            />
            <div className={styles.scrollWrapper} onScroll={handleScroll} ref={rowsRef}>
                {rows.map(row => (
                    <TableRow
                        key={row.key}
                        headers={headers}
                        row={row}
                    />
                ))}
                <Tooltip/>
            </div>
        </div>
    );
};

export default Table;
