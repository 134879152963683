import React from 'react';
import {H1} from "../../components/titles/titles";
import styles from "./HomeScreen.module.css";
import TransactionsChart from "../../components/TransactionsChart/TransactionsChart";
import PaymentSpeedChart from "../../components/PaymentSpeedChart/PaymentSpeedChart";

type Props = {
    transactionsChartData: StatsSuccessfulTransactionsResponse;
    setTransactionsChartData: (data: StatsSuccessfulTransactionsResponse) => void;
    paymentSpeedChartData: StatsPaymentSpeedResponse;
    setPaymentSpeedChartData: (data: StatsPaymentSpeedResponse) => void;
    firstTransactionDate: string;
};

const HomeScreen = ({
                        transactionsChartData,
                        setTransactionsChartData,
                        paymentSpeedChartData,
                        setPaymentSpeedChartData,
                        firstTransactionDate,
                    }: Props) => {
    return (
        <div>
            <div className={styles.titleWrapper}>
                <H1>Successful transactions</H1>
            </div>

            <div className={styles.chartWrapper}>
                <TransactionsChart
                    data={transactionsChartData}
                    setData={setTransactionsChartData}
                    firstTransactionDate={firstTransactionDate}
                />
            </div>

            <div className={styles.titleWrapper}>
                <H1>Payment speed</H1>
            </div>

            <div className={styles.chartWrapper}>
                <PaymentSpeedChart
                    data={paymentSpeedChartData}
                    setData={setPaymentSpeedChartData}
                    firstTransactionDate={firstTransactionDate}
                />
            </div>
        </div>
    );
};

export default HomeScreen;
