import React from 'react';
import styles from "./Checkbox.module.css";
import classNamesBind from "classnames/bind";

const classNames = classNamesBind.bind(styles);

type Props = {
    isChecked: boolean;
    isDisabled?: boolean;
};

const Checkbox = ({
                      isChecked,
                      isDisabled,
                  }: Props) => {
    return (
        <div className={classNames({
            [styles.icon]: true,
            [styles.iconEmpty]: !isChecked,
            [styles.iconChecked]: isChecked,
            [styles.iconInvisible]: isDisabled,
        })}/>
    );
};

export default Checkbox;
