import React from 'react';
import {fetchSmsFiles, isTableHeaderNormalType} from "../../services";
import {smsFileColumns} from "../../components/Table/columns";
import GenericTableScreenWrapper from "../GenericTableScreen/GenericTableScreenWrapper";
import GenericTablePrimaryLink from "../../components/GenericTablePrimaryLink/GenericTablePrimaryLink";
import moment from "moment/moment";
import SmsFilesTableNoResults from "../../components/TableNoResults/SmsFilesTableNoResults";

const SmsFilesScreenWrapper = () => {
    const renderers: TableRenderers<SmsFileTableType> = {
        id: (item) => (
            <GenericTablePrimaryLink
                to={`/sms-files/${item.id}`}
            />
        ),
        dateCreated: (item) => {
            return moment(item.dateCreated).format('DD/MM/YYYY HH:mm');
        },
    };

    return (
        <GenericTableScreenWrapper
            title="Sms files"
            columns={Object.values(smsFileColumns).filter(isTableHeaderNormalType)}
            fetchFunction={fetchSmsFiles}
            itemKey="id"
            storageKey="smsFiles"
            renderers={renderers}
            addModalType="uploadSmsFile"
            tableNoResults={<SmsFilesTableNoResults/>}
        />
    );
};

export default SmsFilesScreenWrapper;
