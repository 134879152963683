import React, {useContext} from 'react';
import {H1} from "../../components/titles/titles";
import Table from "../../components/Table/Table";
import Pager from "../../components/Pager/Pager";
import styles from './GenericTableScreen.module.css';
import HoverCopyTip from "../../components/HoverCopyTip/HoverCopyTip";
import {ModalContext, QueryContext, SidebarContext} from "../../services";
import ActiveFilters from "../../components/ActiveFilters/ActiveFilters";
import IconButton from "../../components/IconButton/IconButton";
import TableNoResults from "../../components/TableNoResults/TableNoResults";
import TableError from "../../components/TableError/TableError";

type Props<T> = {
    title: string;
    headers: TableHeaderNormalType<T>[];
    setHeaders: (headers: TableHeaderNormalType<T>[]) => void;
    allHeaders: TableHeaderNormalType<T>[];
    rows: TableRowType<T>[] | null | undefined;
    nbPages: number;
    queryString: string;
    storageKey: AllowedValuesKey;
    startDownloadFunction?: (searchParams: string, companyId: number) => Promise<DownloadResponse>;
    addModalType?: ModalContentsTypeType;
    tableNoResults?: React.ReactNode;
};

//TODO operator aan filters toevoegen
const GenericTableScreen = <T, >({
                                     title,
                                     headers,
                                     setHeaders,
                                     allHeaders,
                                     rows,
                                     nbPages,
                                     queryString,
                                     storageKey,
                                     startDownloadFunction,
                                     addModalType,
                                     tableNoResults,
                                 }: Props<T>) => {
    const {showSidebar} = useContext(SidebarContext);
    const {showModal} = useContext(ModalContext);

    const handleFilterClick = (header: TableHeaderNormalType<T>) => {
        showSidebar({type: 'tableFilters', filters: [header], allowedValuesKey: storageKey});
    };

    const handleAddClick = () => {
        showModal({
            type: addModalType!,
        });
    };

    const handleFiltersClick = () => {
        showSidebar({type: 'tableFilters', filters: allHeaders, allowedValuesKey: storageKey});
    };

    const handleColumnClick = () => {
        showSidebar({
            type: 'tableColumns',
            defaultHeaders: headers,
            allHeaders: allHeaders,
            onSubmit: setHeaders,
        });
    };

    const handleDownloadClick = () => {
        showModal({
            type: 'download',
            queryString: queryString,
            startDownloadFunction: startDownloadFunction!,
        });
    };

    const hasFilters = headers.filter(header => header.filterable).length > 0;

    return (
        <QueryContext.Provider value={queryString}>
            <div>
                <div className={styles.titleWrapper}>
                    <H1>{title}</H1>
                    <div className={styles.filler}/>
                    <div className={styles.iconsWrapper}>
                        {Boolean(addModalType) && (
                            <div className={styles.iconWrapper}>
                                <IconButton type="add" onClick={handleAddClick}/>
                            </div>
                        )}
                        <div className={styles.iconWrapper}>
                            <IconButton type="columns" onClick={handleColumnClick}/>
                        </div>
                        {hasFilters && (
                            <div className={styles.iconWrapper}>
                                <IconButton type="search" onClick={handleFiltersClick}/>
                            </div>
                        )}
                        {Boolean(startDownloadFunction) && (
                            <div className={styles.iconWrapper}>
                                <IconButton type="download" onClick={handleDownloadClick}/>
                            </div>
                        )}
                    </div>
                </div>

                {/* rows: undefined: not loaded yet */}
                {/* rows: null: error */}
                {/* rows: [].length = 0: no results */}
                {/* rows: [].length > 0: results */}

                {rows !== undefined && <ActiveFilters headers={headers} storageKey={storageKey}/>}

                {rows && (
                    <div className={styles.tableWrapper}>
                        <HoverCopyTip/>
                        <Table
                            headers={headers}
                            rows={rows}
                            onFilterClick={handleFilterClick}
                        />
                    </div>
                )}

                {nbPages > 0 && (
                    <div className={styles.footerWrapper}>
                        <Pager nbPages={nbPages}/>
                    </div>
                )}

                {rows && rows.length === 0 && (
                    <div className={styles.footerWrapper}>
                        {tableNoResults || <TableNoResults/>}
                    </div>
                )}

                {rows === null && (
                    <div className={styles.footerWrapper}>
                        <TableError/>
                    </div>
                )}
            </div>
        </QueryContext.Provider>
    );
};

export default GenericTableScreen;
