import React, {useContext} from 'react';
import ChooseCompanyScreen from "./ChooseCompanyScreen";
import {AppContext} from "../../services";

const ChooseCompanyScreenWrapper = () => {
    const {companies, selectCompany} = useContext(AppContext);

    return (
        <ChooseCompanyScreen
            companies={companies}
            onSelect={selectCompany}
        />
    );
};

export default ChooseCompanyScreenWrapper;
