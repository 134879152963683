import React, {useContext, useState} from 'react';
import styles from "./PaymentSpeedChart.module.css";
import {AppContext, fetchStatsPaymentSpeed} from "../../services";
import RadiosBar from "../RadiosBar/RadiosBar";
import {useLoader} from "../Loader/LoaderProvider";
import {Bar, ComposedChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Label, Area} from "recharts";
import moment from "moment";
import CustomTooltip from "../Charts/CustomTooltip/CustomTooltip";
import SelectYearMonthDay from "../SelectYearMonthDay/SelectYearMonthDay";

export const DEFAULT_GROUP_BY = 'month';

type Props = {
    data: StatsPaymentSpeedResponse;
    setData: (data: StatsPaymentSpeedResponse) => void;
    firstTransactionDate: string;
};

const PaymentSpeedChart = ({
                               data,
                               setData,
                               firstTransactionDate,
                           }: Props) => {
    const {startLoading, stopLoading} = useLoader();
    const [groupBy, setGroupBy] = useState<DayMonthQuarterYear>(DEFAULT_GROUP_BY);
    const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD"));
    const {currentCompanyId} = useContext(AppContext);

    const handleChangeGroupBy = async (newGroupBy: DayMonthQuarterYear) => {
        if (newGroupBy === groupBy) {
            return;
        }

        startLoading();

        const data = await fetchStatsPaymentSpeed(newGroupBy, date, currentCompanyId!);
        setData(data);
        setGroupBy(newGroupBy);

        stopLoading(true);
    };

    const handleChangeDate = async (newDate: string) => {
        if (newDate === date) {
            return;
        }

        startLoading();

        const data = await fetchStatsPaymentSpeed(groupBy, newDate, currentCompanyId!);
        setData(data);
        setDate(newDate);

        stopLoading(true);
    };

    const processedData = [
        {value: data[0], cumulValue: 0, percentage: 0, label: '< 5 min'}, //0 - idx
        {value: data[1], cumulValue: 0, percentage: 0, label: '< 1 hour'}, //1 - idx
        {value: data[2], cumulValue: 0, percentage: 0, label: '< 1 day'}, //2 - idx
        {value: data[3], cumulValue: 0, percentage: 0, label: '< 1 week'}, //3 - idx
        {value: data[4], cumulValue: 0, percentage: 0, label: '< 1 month'}, //4 - idx
        {value: data[5], cumulValue: 0, percentage: 0, label: '> 1 month'}, //5 - idx
    ];

    const sum = processedData.reduce((prev, curr) => prev + curr.value, 0);

    //Calculate cumulValue & percentage
    for (let i = 0; i < processedData.length; i++) {
        processedData[i].cumulValue = (processedData[i - 1]?.cumulValue || 0) + processedData[i].value;
        processedData[i].percentage = processedData[i].cumulValue / sum * 100;
    }

    return (
        <div>
            <div className={styles.formWrapper}>
                <div className={styles.select}>
                    <SelectYearMonthDay
                        groupBy={groupBy}
                        date={date}
                        setDate={handleChangeDate}
                        minDate={firstTransactionDate}
                        alignCalendar="right"
                    />
                </div>
                <RadiosBar
                    options={[
                        {key: 'day', value: 'Day'},
                        {key: 'month', value: 'Month'},
                        {key: 'year', value: 'Year'},
                    ]}
                    value={groupBy}
                    setValue={value => handleChangeGroupBy(value as DayMonthQuarterYear)}
                />
            </div>

            <div className={styles.chartWrapper}>
                {sum > 0 && (
                    <ResponsiveContainer width="100%" height={430}>
                        <ComposedChart width={150} height={40} data={processedData} margin={{top: 120, right: 50, bottom: 50, left: 50}}>
                            <text
                                x={30}
                                y={55}
                                fill="#00683E"
                                fontWeight="bold"
                                fontSize={20}
                            ># payments: {sum}</text>

                            <CartesianGrid
                                stroke="#d9e8e2" //grid lines color
                                vertical={false}
                            />

                            <XAxis
                                dataKey="label"
                                tickLine={false}
                                stroke="#00683E"
                                strokeWidth={2}
                            />

                            <YAxis
                                axisLine={false}
                                tickLine={false}
                                allowDecimals={false}
                                stroke="#00683E" //text color
                                yAxisId="left"
                                dataKey="value"
                            >
                                <Label
                                    value="#"
                                    fill="#00683E"
                                    fontWeight="900"
                                    position="insideTopRight"
                                    dy={-35}
                                />
                            </YAxis>

                            <YAxis
                                axisLine={false}
                                tickLine={false}
                                allowDecimals={false}
                                stroke="#00683E" //text color
                                orientation="right"

                                yAxisId="right"
                                dataKey="percentage"
                            >
                                <Label
                                    value="%"
                                    fill="#00683E"
                                    fontWeight="900"
                                    position="insideTopLeft"
                                    dy={-35}
                                />
                            </YAxis>

                            <Tooltip
                                cursor={false}
                                content={({active, payload}) => {
                                    if (active && payload && payload.length) {
                                        return <CustomTooltip>{payload[0].payload.cumulValue} paid - {payload[0].payload.percentage.toFixed(0)}%</CustomTooltip>;
                                    }

                                    return null;
                                }}
                            />

                            <Area
                                type="linear"
                                dataKey="percentage"
                                dot={{fillOpacity: 1}}
                                activeDot={false}
                                stroke="#2F9B4A" //line color
                                strokeWidth={2} //line width
                                fill="#2F9B4A" //area color
                                yAxisId="right"
                                isAnimationActive={false}
                                fillOpacity={0.035}
                            />

                            <Bar
                                dataKey="value"
                                fill="#90C549"
                                isAnimationActive={false}
                                radius={[3, 3, 0, 0]}
                                yAxisId="left"
                                label={({x, y, width, value}) => {
                                    return (
                                        <g>
                                            <text x={x + width / 2} y={y} dy={-20} textAnchor="middle" fill="#00683E" fontWeight="bold">{value}</text>
                                            <text x={x + width / 2} y={y} dy={-5} textAnchor="middle" fill="#00683E" fontSize={12}>{(value / sum * 100).toFixed(0)}%</text>
                                        </g>
                                    );
                                }}
                            />

                        </ComposedChart>
                    </ResponsiveContainer>
                )}
            </div>
        </div>
    );
};

export default PaymentSpeedChart;
