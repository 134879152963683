import styles from "./CustomTooltip.module.css";
import React from "react";

type Props = {
    children: React.ReactNode;
};

const CustomTooltip = ({
                           children,
                       }: Props) => {
    return (
        <div className={styles.tooltip}>
            {children}
        </div>
    );
};

export default CustomTooltip;
