import {useState} from "react";

export const useHeadersFromLocalStorage = <T>(
    storageKey: string,
    columns: TableHeaderNormalType<T>[],
): [
    TableHeaderNormalType<T>[],
    (headers: TableHeaderNormalType<T>[]) => void,
] => {
    const [headers, setHeaders] = useState<TableHeaderNormalType<T>[]>(() => {
        const storedVisibleHeaderKeysRaw = localStorage.getItem(`columnsConfig:${storageKey}`);
        if (storedVisibleHeaderKeysRaw) {
            const storedVisibleHeaders: { key: keyof T; visible: boolean }[] = JSON.parse(storedVisibleHeaderKeysRaw);
            const storedVisibleHeaderKeys = storedVisibleHeaders.map(storedVisibleHeader => storedVisibleHeader.key);

            return [
                //First return all headers from storage that are known
                ...storedVisibleHeaders
                    .reduce<TableHeaderNormalType<T>[]>((acc, curr) => {
                        const column = columns.find(column => column.key === curr.key);
                        if (!column) {
                            return acc;
                        }

                        return [
                            ...acc,
                            {
                                ...column,
                                visible: curr.visible,
                            },
                        ];
                    }, []),

                //Then return potentially new headers that weren't stored in storage
                ...columns.filter(column => !storedVisibleHeaderKeys.includes(column.key)),
            ];
        } else {
            //Show all headers by default
            return Object.values(columns);
        }
    });

    const handleSetHeaders = (headers: TableHeaderNormalType<T>[]) => {
        //Store in LocalStorage
        const storedColumnConfig = headers
            .map(header => ({
                key: header.key,
                visible: header.visible,
            }));

        localStorage.setItem(`columnsConfig:${storageKey}`, JSON.stringify(storedColumnConfig));

        setHeaders(headers);
    };

    return [
        headers,
        handleSetHeaders,
    ];
};
