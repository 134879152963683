import {useParams, useLocation} from "react-router-dom";
import {useLoader} from "../components/Loader/LoaderProvider";
import {useCallback, useContext, useEffect, useState} from "react";
import {useAlert} from "react-alert";
import queryString from "query-string";
import {AppContext, RefreshContext} from "../services";

type ParamsType = {
    id: string;
}

export const useGenericItemLoader = <T>(fetchFunction: (id: string, companyId: number) => Promise<T>, refreshKeyPrefix: string): T | null | undefined => {
    let {id} = useParams<ParamsType>();
    const location = useLocation();
    const {currentCompanyId} = useContext(AppContext);
    const {registerRefreshKey, unregisterRefreshKey} = useContext(RefreshContext);

    if (!id) {
        id = queryString.parse(location.search, {decode: false}).id as string;
    }

    const {startLoading, stopLoading} = useLoader();
    const alert = useAlert();
    const refreshKey = `${refreshKeyPrefix}-${id}`;

    //undefined: not loaded yet
    //null: loaded with error
    const [item, setItem] = useState<T | null | undefined>(undefined);

    const fetchItem = useCallback(async () => {
        startLoading();

        try {
            const item = await fetchFunction(id, currentCompanyId!);
            setItem(item);
        } catch (error) {
            if (error !== 404) {
                alert.error('An error occurred. Refresh this page or try again later.');
            }

            setItem(null);
        }

        stopLoading();
    }, [id, fetchFunction, startLoading, stopLoading, alert, currentCompanyId]);

    //Do initial load or subsequent loads when url parameter changes
    useEffect(
        () => {
            const refreshFunc = async () => {
                await fetchItem();
            };

            //So we can refresh this page from other places in the app
            registerRefreshKey(refreshKey, refreshFunc);

            //Do initial load for the current page
            (async () => {
                await refreshFunc();
            })();

            return () => {
                unregisterRefreshKey(refreshKey, refreshFunc);
            };
        },
        [fetchItem, refreshKey, registerRefreshKey, unregisterRefreshKey]
    );

    return item;
};
