import React from 'react';
import styles from './Textarea.module.css';
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

export interface TextareaProps {
    value?: string;
    setValue: (value: string) => void;
    title?: string;
    placeholder?: string;
    border?: boolean;
    rows?: number;
}

const Textarea = ({
                      value = '',
                      setValue,
                      title,
                      placeholder,
                      border,
                      rows,
                  }: TextareaProps) => {
    return (
        <div>
            {title && <div className={styles.title}>{title}</div>}
            <div className={styles.wrapper}>
                <textarea
                    className={classNames({
                        [styles.textarea]: true,
                        [styles.textareaWithBorder]: border,
                    })}
                    placeholder={placeholder}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    rows={rows}
                />
            </div>
        </div>
    );
};

export default Textarea;
