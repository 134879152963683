import React from 'react';
import styles from './titles.module.css';

type HProps = {
    children: React.ReactNode;
};

export const H1 = ({children}: HProps) => {
    return (
        <h1 className={styles.h1}>{children}</h1>
    );
};

export const H2 = ({children}: HProps) => {
    return (
        <h2 className={styles.h2}>{children}</h2>
    );
};

export const H3 = ({children}: HProps) => {
    return (
        <h3 className={styles.h3}>{children}</h3>
    );
};


