import React from 'react';
import DocumentScreen from "./DocumentScreen";
import {fetchDocument} from "../../services";
import {useGenericItemLoader} from "../../hooks/useGenericItemLoader";

const DocumentScreenWrapper = () => {
    const document = useGenericItemLoader(fetchDocument, 'document');

    return (
        <DocumentScreen document={document}/>
    );
};

export default DocumentScreenWrapper;
