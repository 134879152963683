import React, {useState} from 'react';
import styles from './Textfield.module.css';
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

export interface TextfieldProps {
    value?: string;
    setValue: (value: string) => void;
    title?: string;
    type?: 'text' | 'password' | 'email' | 'number';
    placeholder?: string;
    clearable?: boolean;
    autoFocus?: boolean;
    border?: boolean;
}

const Textfield = ({
                       value = '',
                       setValue,
                       title,
                       type: defaultType = 'text',
                       placeholder,
                       clearable,
                       autoFocus,
                       border,
                   }: TextfieldProps) => {
    const [type, setType] = useState(defaultType);

    return (
        <div>
            {title && <div className={styles.title}>{title}</div>}
            <div className={styles.wrapper}>
                <input
                    className={classNames({
                        [styles.textfield]: true,
                        [styles.textfieldWithIcon]: defaultType === 'password' || clearable,
                        [styles.textfieldWithBorder]: border,
                    })}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    autoFocus={autoFocus}
                />

                {defaultType === 'password' && (
                    <div
                        className={classNames({
                            [styles.icon]: true,
                            [styles.iconPasswordShow]: type === 'password',
                            [styles.iconPasswordHide]: type !== 'password',
                        })}
                        onClick={() => setType(type === 'password' ? 'text' : 'password')}
                    />
                )}

                {defaultType !== 'password' && clearable && value && (
                    <div
                        className={classNames(styles.icon, styles.iconClear)}
                        onClick={() => setValue('')}
                    />
                )}
            </div>
        </div>
    );
};

export default Textfield;
