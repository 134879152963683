import React, {useContext, useState} from 'react';
import styles from "./Modal.module.css";
import LoadingButton from "../Button/LoadingButton";
import {processSmsFile, RefreshContext} from "../../services";
import innerStyles from "./ModalUploadSmsFileContainer.module.css";
import Checkbox from "../Checkbox/Checkbox";

type Props = {
    onClose: () => void;
    smsFileId: number;
    defaultMailWhenComplete: boolean;
};

const ModalProcessSmsFileContainer = ({
                                         onClose,
                                         smsFileId,
                                         defaultMailWhenComplete,
                                     }: Props) => {
    const {refresh} = useContext(RefreshContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [mailWhenComplete, setMailWhenComplete] = useState<boolean>(defaultMailWhenComplete);

    const handleSubmit = async () => {
        try {
            setErrorMessage('');
            await processSmsFile(smsFileId, mailWhenComplete);
            onClose();
            refresh(`smsFile-${smsFileId}`);
        } catch (error) {
            setErrorMessage(error.message || 'An error occurred. Refresh this page or try again later.');
        }
    };

    return (
        <div className={styles.modal}>
            <div className={styles.title}>Are you sure you want to start processing this sms file?</div>
            <div className={styles.body}>
                <div className={innerStyles.spacer}>This will create paylinks and send text messages.</div>
                <div className={innerStyles.notificationWrapper}>
                    <div className={innerStyles.notificationCheckboxWrapper} onClick={() => setMailWhenComplete(mailWhenComplete => !mailWhenComplete)}>
                        <Checkbox isChecked={mailWhenComplete}/>
                    </div>
                    <div className={innerStyles.notificationTextWrapper}>Receive an e-mail once the file is processed</div>
                </div>
            </div>

            {errorMessage && (
                <div className={styles.errorWrapper}>
                    {errorMessage}
                </div>
            )}
            <LoadingButton label="Process file" onClick={handleSubmit}/>
        </div>
    );
};

export default ModalProcessSmsFileContainer;
