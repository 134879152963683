import React, {useContext, useRef} from 'react';
import {BrowserRouter, Switch, Redirect, Route} from "react-router-dom";
import {AppContext} from "../services";
import LoginScreenWrapper from "../screens/LoginScreen/LoginScreenWrapper";
import HomeScreenWrapper from "../screens/HomeScreen/HomeScreenWrapper";
// import DocumentsScreenWrapper from "../screens/DocumentsScreen/DocumentsScreenWrapper";
import TransactionsScreenWrapper from "../screens/TransactionsScreen/TransactionsScreenWrapper";
import styles from "./AppNavigation.module.css";
import MenuLeftWrapper from "../components/MenuLeft/MenuLeftWrapper";
import SidebarProvider from "../components/Sidebar/SidebarProvider";
import ChooseCompanyScreenWrapper from "../screens/ChooseCompanyScreen/ChooseCompanyScreenWrapper";
import LoaderProvider from "../components/Loader/LoaderProvider";
import DocumentScreenWrapper from "../screens/DocumentScreen/DocumentScreenWrapper";
import TransactionScreenWrapper from "../screens/TransactionScreen/TransactionScreenWrapper";
import AccountScreenWrapper from "../screens/AccountScreen/AccountScreenWrapper";
import ModalProvider from "../components/Modal/ModalProvider";
import ExportsScreenWrapper from "../screens/ExportsScreen/ExportsScreenWrapper";
import SmsFilesScreenWrapper from "../screens/SmsFilesScreen/SmsFilesScreenWrapper";
import SmsFileScreenWrapper from "../screens/SmsFileScreen/SmsFileScreenWrapper";
import RefreshProvider from "../components/RefreshProvider/RefreshProvider";
import PaylinksFilesScreenWrapper from "../screens/SmsFilesScreen/PaylinksFilesScreenWrapper";
import PaylinksFileScreenWrapper from "../screens/SmsFileScreen/PaylinksFileScreenWrapper";

const AppNavigation = () => {
    const {loggedIn, companies, currentCompanyId} = useContext(AppContext);
    const company = companies.find(company => company.id === currentCompanyId);
    const showSmsPage: boolean = Boolean(company?.smsSenders?.length);
    const showPaylinksPage: boolean = Boolean(company?.paylinkSenders?.length);
    const contentRef = useRef<HTMLDivElement>(null);

    if (!loggedIn) {
        return <LoginScreenWrapper/>;
    }

    if (!currentCompanyId) {
        return <ChooseCompanyScreenWrapper/>;
    }


    return (
        <BrowserRouter>
            <RefreshProvider>
                <SidebarProvider>
                    <ModalProvider>
                        <div className={styles.wrapper}>
                            <div className={styles.menuWrapper}>
                                <Route
                                    component={MenuLeftWrapper}
                                    path={[
                                        '/home',
                                        // '/documents',
                                        '/transactions',
                                        '/exports',
                                        '/account',
                                        '/sms-files',
                                        '/paylinks-files',
                                    ]}
                                />
                            </div>
                            <div className={styles.content} ref={contentRef}>
                                <LoaderProvider contentRef={contentRef}>
                                    <Switch>
                                        <Route path="/home" component={HomeScreenWrapper}/>
                                        {/*<Route exact path="/documents" component={DocumentsScreenWrapper}/>*/}
                                        {/*<Route exact path="/documents/:id" component={DocumentScreenWrapper}/>*/}
                                        <Route exact path="/transactions" component={TransactionsScreenWrapper}/>
                                        <Route exact path="/transactions/document" component={DocumentScreenWrapper}/>
                                        <Route exact path="/transactions/:id" component={TransactionScreenWrapper}/>
                                        <Route exact path="/exports" component={ExportsScreenWrapper}/>
                                        {showSmsPage && <Route exact path="/sms-files" component={SmsFilesScreenWrapper}/>}
                                        {showSmsPage && <Route exact path="/sms-files/:id" component={SmsFileScreenWrapper}/>}
                                        {showPaylinksPage && <Route exact path="/paylinks-files" component={PaylinksFilesScreenWrapper}/>}
                                        {showPaylinksPage && <Route exact path="/paylinks-files/:id" component={PaylinksFileScreenWrapper}/>}
                                        <Route path="/account" component={AccountScreenWrapper}/>
                                        <Redirect to="/home"/>
                                    </Switch>
                                </LoaderProvider>
                            </div>
                        </div>
                    </ModalProvider>
                </SidebarProvider>
            </RefreshProvider>
        </BrowserRouter>
    );
};

export default AppNavigation;
