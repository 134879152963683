import React, {useState} from 'react';
import styles from './CalendarQuarter.module.css';
import moment from 'moment';
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

export interface CalendarProps {
    title: string;
    value: Date;
    setValue: (value: Date) => void;
}

const CalendarQuarter = ({
                           title,
                           value,
                           setValue,
                       }: CalendarProps) => {
    const [year, setYear] = useState(moment(value).year());

    const goToPreviousYear = () => {
        setYear(year - 1);
    };

    const goToNextYear = () => {
        setYear(year + 1);
    };

    return (
        <div className={styles.wrapper} onClick={e => e.stopPropagation()}>
            <div className={styles.title}>{title}</div>

            <div className={styles.bar}>
                <div className={`${styles.icon} ${styles.iconLeft}`} onClick={goToPreviousYear}/>
                <div className={styles.barItem}>{year}</div>
                <div className={`${styles.icon} ${styles.iconRight}`} onClick={goToNextYear}/>
            </div>

            <div className={styles.quarters}>
                {[1, 2, 3, 4].map(quarter => {
                    const quarterMoment = moment().set('year', year).set('quarter', quarter);

                    return (
                        <div
                            key={quarter}
                            className={classNames(styles.quarter, {
                                [styles.today]: quarterMoment.isSame(moment(), 'quarter'),
                                [styles.selected]: quarterMoment.isSame(moment(value), 'quarter'),
                            })}
                            onClick={() => setValue(quarterMoment.toDate())}
                        >
                            <span>Q{quarter}</span>
                        </div>
                    );
                })}
            </div>

        </div>
    );
};

export default CalendarQuarter;
