import React from 'react';
import {fetchPaylinksFiles, isTableHeaderNormalType} from "../../services";
import {paylinksFileColumns} from "../../components/Table/columns";
import GenericTableScreenWrapper from "../GenericTableScreen/GenericTableScreenWrapper";
import GenericTablePrimaryLink from "../../components/GenericTablePrimaryLink/GenericTablePrimaryLink";
import moment from "moment/moment";
import PaylinksFilesTableNoResults from "../../components/TableNoResults/PaylinksFilesTableNoResults";

const PaylinkFilesScreenWrapper = () => {
    const renderers: TableRenderers<PaylinksFileTableType> = {
        id: (item) => (
            <GenericTablePrimaryLink
                to={`/paylinks-files/${item.id}`}
            />
        ),
        dateCreated: (item) => {
            return moment(item.dateCreated).format('DD/MM/YYYY HH:mm');
        },
    };

    return (
        <GenericTableScreenWrapper
            title="Paylinks files"
            columns={Object.values(paylinksFileColumns).filter(isTableHeaderNormalType)}
            fetchFunction={fetchPaylinksFiles}
            itemKey="id"
            storageKey="paylinksFiles"
            renderers={renderers}
            addModalType="uploadPaylinksFile"
            tableNoResults={<PaylinksFilesTableNoResults/>}
        />
    );
};

export default PaylinkFilesScreenWrapper;
