import React from 'react';
import styles from './TableHeader.module.css';
import classNamesBind from 'classnames/bind';
import {useSavedQueryParts} from "../../hooks/useQueryParts";
import {Link} from "react-router-dom";

const classNames = classNamesBind.bind(styles);

export interface TableHeaderProps<T> {
    headers: TableHeaderNormalType<T>[];
    onFilterClick: (header: TableHeaderNormalType<T>) => void;
    scrollRef: React.RefObject<HTMLDivElement>;
}

const TableHeader = <T, >({
                              headers,
                              onFilterClick,
                              scrollRef,
                          }: TableHeaderProps<T>) => {
    return (
        <div className={styles.sticky} ref={scrollRef}>
            <div className={styles.wrapper}>
                {headers.filter(header => header.visible).map(header => (
                    <TableHeaderItem
                        key={String(header.key)}
                        header={header}
                        onFilterClick={onFilterClick}
                    />
                ))}
            </div>
        </div>
    );
};

type TableHeaderItemProps<T> = {
    header: TableHeaderNormalType<T>;
    onFilterClick: (header: TableHeaderNormalType<T>) => void;
};

const TableHeaderItem = <T, >({
                                  header,
                                  onFilterClick,
                              }: TableHeaderItemProps<T>) => {
    const {parsedQuery, buildUrl} = useSavedQueryParts();

    let sortAsc = null;
    if (parsedQuery.sortField === header.key) {
        sortAsc = parsedQuery.sortOrder !== 'desc';
    }

    return (
        <div className={styles.header} style={{width: header.width, ...header.style}}>
            <div>{header.title}</div>
            {header.sortable && (
                <Link
                    className={classNames({
                        [styles.icon]: true,
                        [styles.sortableIcon]: sortAsc === null,
                        [styles.sortableUpIcon]: sortAsc === true,
                        [styles.sortableDownIcon]: sortAsc === false,
                    })}
                    to={buildUrl({sortField: header.key, sortOrder: !sortAsc ? 'asc' : 'desc', page: undefined})}
                />
            )}
            {header.filterable && (
                <div
                    className={classNames({
                        [styles.icon]: true,
                        [styles.filterableIcon]: true,
                    })}
                    onClick={() => onFilterClick(header)}
                />
            )}
        </div>
    );
};

export default TableHeader;
