import React from 'react';
import {CheckboxOption} from "./Checkboxes";

type Props = {
    items: {
        item: CheckboxOption;
        node: React.ReactNode;
    }[];
};

const CheckboxesNormalWrapper = ({
                                     items,
                                 }: Props) => {
    return (
        <div>
            {items.map(item => item.node)}
        </div>
    );
};

export default CheckboxesNormalWrapper;
