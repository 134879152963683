import React from 'react';
import Modal from "./Modal";
import ModalDownloadContainer from "./ModalDownloadContainer";
import ModalUploadSmsFileContainer from "./ModalUploadSmsFileContainer";
import ModalProcessSmsFileContainer from "./ModalProcessSmsFileContainer";
import ModalCancelSmsFileContainer from "./ModalCancelSmsFileContainer";
import ModalProcessPaylinksFileContainer from "./ModalProcessPaylinksFileContainer";
import ModalCancelPaylinksFileContainer from "./ModalCancelPaylinksFileContainer";
import ModalUploadPaylinksFileContainer from "./ModalUploadPaylinksFileContainer";

type Props = {
    onClose: () => void;
    visible: boolean;
    modalContents: ModalContentsType;
};

const ModalContainer = ({
                            visible,
                            onClose,
                            modalContents,
                        }: Props) => {
    let children: React.ReactNode = '';

    switch (modalContents.type) {
        case "download":
            children = (
                <ModalDownloadContainer
                    startDownloadFunction={modalContents.startDownloadFunction}
                    queryString={modalContents.queryString}
                    exportId={modalContents.exportId}
                    onClose={onClose}
                />
            );
            break;
        case "uploadSmsFile":
            children = (
                <ModalUploadSmsFileContainer
                    onClose={onClose}
                />
            );
            break;
        case "processSmsFile":
            children = (
                <ModalProcessSmsFileContainer
                    onClose={onClose}
                    smsFileId={modalContents.smsFileId!}
                    defaultMailWhenComplete={modalContents.mailWhenComplete!}
                />
            );
            break;
        case "cancelSmsFile":
            children = (
                <ModalCancelSmsFileContainer
                    onClose={onClose}
                    smsFileId={modalContents.smsFileId!}
                />
            );
            break;
        case "uploadPaylinksFile":
            children = (
                <ModalUploadPaylinksFileContainer
                    onClose={onClose}
                />
            );
            break;
        case "processPaylinksFile":
            children = (
                <ModalProcessPaylinksFileContainer
                    onClose={onClose}
                    paylinksFileId={modalContents.paylinksFileId!}
                    defaultMailWhenComplete={modalContents.mailWhenComplete!}
                />
            );
            break;
        case "cancelPaylinksFile":
            children = (
                <ModalCancelPaylinksFileContainer
                    onClose={onClose}
                    paylinksFileId={modalContents.paylinksFileId!}
                />
            );
            break;
        case "empty":
            //Leave children empty
            break;
        default:
            //Union Exhaustiveness checking (https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html)
            return assertNever(modalContents);
    }

    return (
        <Modal visible={visible} onClose={onClose}>
            {children}
        </Modal>
    );
};

const assertNever = (x: never): null => {
    return null;
};

export default ModalContainer;
