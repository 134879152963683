import React, {Dispatch} from 'react';
import TextfieldDate from "../TextfieldDate/TextfieldDate";
import moment from "moment";
import {FiltersReducerAction, FiltersType} from "./SidebarFilters";
import SidebarFilterRow from "./SidebarFilterRow";

type Props<T> = {
    headerConfig: TableHeaderNormalType<T>;
    activeFilters: FiltersType;
    dispatch: Dispatch<FiltersReducerAction>;
};

//Format: 2021-02-03
const SidebarFilterDate = <T, >({
                                    headerConfig,
                                    activeFilters,
                                    dispatch,
                                }: Props<T>) => {
    let usedValue = undefined;
    if (activeFilters[`${headerConfig.key}`]) {
        const momentDate = moment(activeFilters[`${headerConfig.key}`]);
        if (momentDate.isValid()) {
            usedValue = momentDate.toDate();
        }
    }

    let usedValue_to = undefined;
    if (activeFilters[`${headerConfig.key}_to`]) {
        const momentDate = moment(activeFilters[`${headerConfig.key}_to`]);
        if (momentDate.isValid()) {
            usedValue_to = momentDate.toDate();
        }
    }

    const handleSetValue = (value: Date | undefined, key: string) => {
        const newValue = value ? moment(value).format('YYYY-MM-DD') : undefined;
        dispatch({
            type: 'setValue',
            key: key,
            value: newValue,
        })
    };

    if (headerConfig.filterableRange) {
        return (
            <SidebarFilterRow
                left={(
                    <TextfieldDate
                        title="From"
                        value={usedValue}
                        setValue={value => handleSetValue(value, `${headerConfig.key}`)}
                        alignCalendar="left"
                    />
                )}
                right={(
                    <TextfieldDate
                        title="To"
                        value={usedValue_to}
                        setValue={value => handleSetValue(value, `${headerConfig.key}_to`)}
                        alignCalendar="right"
                    />
                )}
            />
        );
    } else {
        return (
            <TextfieldDate
                title={headerConfig.title}
                value={usedValue}
                setValue={value => handleSetValue(value, `${headerConfig.key}`)}
            />
        );
    }

};

export default SidebarFilterDate;
