import React from 'react';
import styles from './SelectYearMonthDay.module.css';
import moment from "moment";
import classNames from 'classnames';
import TextfieldDate from "../TextfieldDate/TextfieldDate";
import TextfieldMonth from "../TextfieldMonth/TextfieldMonth";
import TextfieldQuarter from "../TextfieldQuarter/TextfieldQuarter";
import TextfieldYear from "../TextfieldYear/TextfieldYear";

type Props = {
    groupBy: DayMonthQuarterYear;
    date: string;
    setDate: (date: string) => void;
    minDate: string;
    label?: string;
    alignCalendar?: 'left' | 'right';
};

const SelectYearMonthDay = ({
                                groupBy,
                                date,
                                setDate,
                                minDate,
                                label,
                                alignCalendar,
                            }: Props) => {
    const momentDay = moment(date);

    return (
        <div className={styles.wrapper}>
            {label && (
                <div className={styles.label}>{label}</div>
            )}
            {groupBy === 'day' && (
                <div className={styles.select}>
                    <TextfieldDate
                        title={label ? `${label} date` : 'Date'}
                        value={momentDay.toDate()}
                        setValue={value => setDate(moment(value).format('YYYY-MM-DD'))}
                        alignCalendar={alignCalendar}
                        hideLabel
                        clearable={false}
                    />
                </div>
            )}
            {(groupBy === 'month') && (
                <div className={classNames(styles.select, styles.month)}>
                    <TextfieldMonth
                        title={label ? `${label} month` : 'Month'}
                        value={momentDay.toDate()}
                        setValue={value => setDate(moment(value).format('YYYY-MM-DD'))}
                        alignCalendar={alignCalendar}
                    />
                </div>
            )}
            {(groupBy === 'quarter') && (
                <div className={classNames(styles.select, styles.quarter)}>
                    <TextfieldQuarter
                        title={label ? `${label} quarter` : 'Quarter'}
                        value={momentDay.toDate()}
                        setValue={value => setDate(moment(value).format('YYYY-MM-DD'))}
                        alignCalendar={alignCalendar}
                    />
                </div>
            )}
            {(groupBy === 'year') && (
                <div className={styles.select}>
                    <TextfieldYear
                        value={momentDay.toDate()}
                        setValue={value => setDate(moment(value).format('YYYY-MM-DD'))}
                        minDate={moment(minDate).toDate()}
                    />
                </div>
            )}
        </div>
    );
};

export default SelectYearMonthDay;
