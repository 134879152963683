import React from 'react';
import styles from './TableRow.module.css';
import {useAlert} from 'react-alert';
import copy from 'copy-to-clipboard';

export interface TableRowProps<T> {
    headers: TableHeaderNormalType<T>[];
    row: TableRowType<T>;
}

const TableRow = <T, >({
                           headers,
                           row,
                       }: TableRowProps<T>) => {
    return (
        <div>
            <div className={styles.wrapper}>
                {headers.filter(header => header.visible).map(header => (
                    <TableRowItem
                        key={String(header.key)}
                        header={header}
                        row={row}
                    />
                ))}
            </div>
        </div>
    );
};

type TableRowItemProps<T> = {
    header: TableHeaderNormalType<T>;
    row: TableRowType<T>;
};

const TableRowItem = <T, >({
                               header,
                               row,
                           }: TableRowItemProps<T>) => {
    const alert = useAlert();

    const contents = row[header.key];

    const handleClick = () => {
        if (typeof contents !== 'object') {
            copy(String(contents));
            alert.success(`'${contents}' was copied to your clipboard`);
        }
    };

    return (
        <div
            className={styles.itemWrapper}
            style={{
                ...header.style,
                width: header.width,
            }}
        >
            <div
                className={styles.item}
                data-tip={typeof contents !== 'object' ? contents : undefined}
                onClick={handleClick}
            >
                {contents}
            </div>
        </div>
    );
};

export default TableRow;
