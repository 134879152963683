import {useLocation} from "react-router-dom";
import queryString from "query-string";

export const useQueryParams = <T>(headers: TableHeaderNormalType<T>[]) => {
    const location = useLocation();
    const parsedQueryString = queryString.parse(location.search);

    const keysFromHeaders = headers.filter(header => header.filterable).reduce((keysFromHeaders, header) => {
        return [
            ...keysFromHeaders,
            `${header.key}`, //regular filter
            `${header.key}_op`, //operator: contains, equals,...
            `${header.key}_to`, //range filter
        ];
    }, [] as string[]);

    const keysWithGlobals = [
        ...keysFromHeaders,
        'page',
        'sortField',
        'sortOrder',
    ];

    //Only include keys that the current table can read
    const filteredQueryParams = Object.keys(parsedQueryString)
        .reduce((filteredQueryParams, key) => {
            if (keysWithGlobals.includes(key)) {
                return {
                    ...filteredQueryParams,
                    [key]: parsedQueryString[key],
                };
            } else {
                return filteredQueryParams;
            }
        }, {});

    return queryString.stringify(filteredQueryParams);
};
