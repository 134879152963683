import React from 'react';
import styles from './LoginScreen.module.css';
import Button from "../../components/Button/Button";
import Textfield from "../../components/Textfield/Textfield";

export interface LoginScreenProps {
    username: string;
    setUsername: (username: string) => void;
    password: string;
    setPassword: (password: string) => void;
    onLogin: () => Promise<void>;
    loading: boolean;
    showError: boolean;
}

const LoginScreen = ({
                         username,
                         setUsername,
                         password,
                         setPassword,
                         onLogin,
                         loading,
                         showError,
                     }: LoginScreenProps) => {
    const buttonDisabled = !username || !password;

    return (
        <div className={styles.wrapper}>
            <div className={styles.modal}>
                <div className={styles.logo}/>

                {showError && (
                    <div className={styles.error}>
                        Wrong combination of username and password.
                    </div>
                )}

                <form onSubmit={e => e.preventDefault()}>
                    <div className={styles.usernameWrapper}>
                        <Textfield
                            placeholder="User name"
                            value={username}
                            setValue={setUsername}
                            autoFocus
                        />
                    </div>

                    <div className={styles.passwordWrapper}>
                        <Textfield
                            placeholder="Password"
                            type="password"
                            value={password}
                            setValue={setPassword}
                        />
                    </div>

                    <div className={styles.buttonWrapper}>
                        <Button
                            label="Log in"
                            onClick={onLogin}
                            loading={loading}
                            disabled={buttonDisabled}
                        />
                    </div>
                </form>

            </div>
        </div>
    );
};

export default LoginScreen;
