import React, {useState} from 'react';
import styles from './CalendarMonth.module.css';
import moment from 'moment';
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

export interface CalendarProps {
    title: string;
    value: Date;
    setValue: (value: Date) => void;
}

const CalendarMonth = ({
                           title,
                           value,
                           setValue,
                       }: CalendarProps) => {
    const [year, setYear] = useState(moment(value).year());

    const goToPreviousYear = () => {
        setYear(year - 1);
    };

    const goToNextYear = () => {
        setYear(year + 1);
    };

    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    return (
        <div className={styles.wrapper} onClick={e => e.stopPropagation()}>
            <div className={styles.title}>{title}</div>

            <div className={styles.bar}>
                <div className={`${styles.icon} ${styles.iconLeft}`} onClick={goToPreviousYear}/>
                <div className={styles.barItem}>{year}</div>
                <div className={`${styles.icon} ${styles.iconRight}`} onClick={goToNextYear}/>
            </div>

            <div className={styles.months}>
                {months.map((month, i) => {
                    const monthMoment = moment().set('year', year).set('month', i);

                    return (
                        <div
                            key={i}
                            className={classNames(styles.month, {
                                [styles.today]: monthMoment.isSame(moment(), 'month'),
                                [styles.selected]: monthMoment.isSame(moment(value), 'month'),
                            })}
                            onClick={() => setValue(monthMoment.toDate())}
                        >
                            <span>{month}</span>
                        </div>
                    );
                })}
            </div>

        </div>
    );
};

export default CalendarMonth;
