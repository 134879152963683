import React from 'react';
import styles from "./FieldsPane.module.css";

type Props = {
    children: React.ReactNode;
};

const FieldsPane = ({children}: Props) => {
    return (
        <div className={styles.pane}>
            {children}
        </div>
    );
};

export const FieldsCol = ({children}: Props) => {
    return (
        <div className={styles.col}>
            {children}
        </div>
    );
};

export default FieldsPane;
