import React from 'react';
import styles from './Button.module.css';
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

export interface ButtonProps {
    backgroundColor?: 'green' | 'red' | 'transparent';
    label: string;
    disabled?: boolean;
    href: string;
}

const ButtonLink = ({
                        backgroundColor = 'green',
                        label,
                        disabled,
                        href,
                    }: ButtonProps) => {
    return (
        <a
            href={href}
            children={label}
            className={classNames({
                [styles.button]: true,
                [styles.buttonDisabled]: disabled,
                [styles.buttonGreen]: backgroundColor === 'green',
                [styles.buttonRed]: backgroundColor === 'red',
                [styles.buttonTransparent]: backgroundColor === 'transparent',
            })}
        />
    );
};

export default ButtonLink;
