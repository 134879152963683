import React from 'react';
import styles from './TooSmallScreen.module.css';

const TooSmallScreen = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.modal}>
                <div className={styles.logoWrapper}>
                    <div className={styles.logo}/>
                </div>

                <div className={styles.text}>
                    POM is currently not available on this screen size. Open POM on a larger screen to make use of all its functionalities!
                </div>
            </div>
        </div>
    );
};

export default TooSmallScreen;
