import React from 'react';
import {useSavedQueryParts} from "../../hooks/useQueryParts";
import styles from "./ActiveFilters.module.css";
import ActiveFilter from "../ActiveFilter/ActiveFilter";

type Props<T> = {
    headers: TableHeaderNormalType<T>[];
    storageKey: AllowedValuesKey;
};

const ActiveFilters = <T, >({
                                headers,
                                storageKey,
                            }: Props<T>) => {
    const {parsedQuery, buildUrl} = useSavedQueryParts();

    return (
        <div className={styles.wrapper}>
            {headers.map(header => {
                const key = String(header.key);
                if (!parsedQuery[key] && !parsedQuery[`${key}_to`]) return null;

                const link = buildUrl({
                    [key]: undefined,
                    [`${key}_op`]: undefined,
                    [`${key}_to`]: undefined,
                });

                return (
                    <div className={styles.item} key={key}>
                        <ActiveFilter
                            header={header}
                            value={parsedQuery[key] as string}
                            valueTo={parsedQuery[`${key}_to`] as string}
                            link={link}
                            storageKey={storageKey}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default ActiveFilters;
