import React from 'react';
import styles from './Field.module.css';

type Props = {
    label: React.ReactNode;
    children: React.ReactNode;
};

const Field = ({label, children}: Props) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.label}>{label}</div>
            <div className={styles.value}>{children || '-'}</div>
        </div>
    );
};

export default Field;
