import React, {useContext, useState} from 'react';
import PomBarChart from "../PomBarChart/PomBarChart";
import styles from "./TransactionsChart.module.css";
import {AppContext, fetchStatsSuccessfulTransactions} from "../../services";
import RadiosBar from "../RadiosBar/RadiosBar";
import moment from "moment";
import {useLoader} from "../Loader/LoaderProvider";
import SelectYearMonthDay from "../SelectYearMonthDay/SelectYearMonthDay";

export const DEFAULT_GROUP_BY: DayMonthQuarterYear = 'day';

type Props = {
    data: StatsSuccessfulTransactionsResponse;
    setData: (data: StatsSuccessfulTransactionsResponse) => void;
    firstTransactionDate: string;
};

const TransactionsChart = ({
                               data,
                               setData,
                               firstTransactionDate,
                           }: Props) => {
    const {startLoading, stopLoading} = useLoader();
    const [groupBy, setGroupBy] = useState<DayMonthQuarterYear>(DEFAULT_GROUP_BY);
    const [fromDate, setFromDate] = useState<string>(moment().subtract(1, 'month').format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState<string>(moment().format("YYYY-MM-DD"));
    const {currentCompanyId} = useContext(AppContext);

    const handleChangeGroupBy = async (newGroupBy: DayMonthQuarterYear) => {
        if (newGroupBy === groupBy) {
            return;
        }

        startLoading();

        const data = await fetchStatsSuccessfulTransactions(newGroupBy, fromDate, toDate, currentCompanyId!);
        setData(data);
        setGroupBy(newGroupBy);

        stopLoading(true);
    };

    const handleChangeFromDate = async (newFromDate: string) => {
        if (newFromDate === fromDate) {
            return;
        }

        startLoading();

        const data = await fetchStatsSuccessfulTransactions(groupBy, newFromDate, toDate, currentCompanyId!);
        setData(data);
        setFromDate(newFromDate);

        stopLoading(true);
    };

    const handleChangeToDate = async (newToDate: string) => {
        if (newToDate === toDate) {
            return;
        }

        startLoading();

        const data = await fetchStatsSuccessfulTransactions(groupBy, fromDate, newToDate, currentCompanyId!);
        setData(data);
        setToDate(newToDate);

        stopLoading(true);
    };

    let dateFormat = 'DD/MM/YY';
    switch (groupBy) {
        case "month":
            dateFormat = 'MMMM YYYY';
            break;

        case "quarter":
            dateFormat = '\\QQ YYYY';
            break;

        case "year":
            dateFormat = 'YYYY';
            break;
    }

    let barChartData: BarChartDataType[] = [];
    const day = moment(fromDate).startOf(groupBy);
    const toDateMoment = moment(toDate);
    while (day.isSameOrBefore(toDateMoment)) {
        const searchFormat = day.format('YYYY-MM-DD');
        barChartData.push({
            label: day.format(dateFormat),
            value: data.find(({date}) => date === searchFormat)?.value || 0,
        });
        day.add('1', groupBy);
    }

    return (
        <div>
            <div className={styles.formWrapper}>
                <SelectYearMonthDay
                    groupBy={groupBy}
                    date={fromDate}
                    setDate={handleChangeFromDate}
                    minDate={firstTransactionDate}
                    label="From"
                    alignCalendar="left"
                />
                <SelectYearMonthDay
                    groupBy={groupBy}
                    date={toDate}
                    setDate={handleChangeToDate}
                    minDate={firstTransactionDate}
                    label="To"
                    alignCalendar="right"
                />
                <RadiosBar
                    options={[
                        {key: 'day', value: 'Day'},
                        {key: 'month', value: 'Month'},
                        {key: 'quarter', value: 'Quarter'},
                        {key: 'year', value: 'Year'},
                    ]}
                    value={groupBy}
                    setValue={value => handleChangeGroupBy(value as DayMonthQuarterYear)}
                />
            </div>

            <div className={styles.chartWrapper}>
                <PomBarChart data={barChartData}/>
            </div>
        </div>
    );
};

export default TransactionsChart;
