import React from 'react';
import styles from "./PaymentStatusAmount.module.css";
import PaymentStatus from "../PaymentStatus/PaymentStatus";
import {formatAmount} from "../../services";

type Props = {
    status: PaymentStatusType;
    amount: number;
};

const PaymentStatusAmount = ({
                                 status,
                                 amount,
                             }: Props) => {
    return (
        <div className={styles.row}>
            <div className={styles.statusWrapper}>
                <PaymentStatus status={status}/>
            </div>
            <div className={styles.amount}>{formatAmount(amount)}</div>
        </div>
    );
};

export default PaymentStatusAmount;
