import React, {useState} from 'react';
import Button, {ButtonProps} from "./Button";

interface Props extends Omit<ButtonProps, "loading"> {
    onClick?: () => Promise<void>;
}

const LoadingButton = ({
                           onClick,
                           ...props
                       }: Props) => {
    const [loading, setLoading] = useState(false);

    const handlePress = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        onClick && await onClick();
        setLoading(false);
    };

    return (
        <Button
            {...props}
            onClick={handlePress}
            loading={loading}
        />
    );
};

export default LoadingButton;
