import React, {useRef, useState} from 'react';
import {SidebarContext} from "../../services";
import Sidebar from "./Sidebar";

type Props = {
    children: React.ReactNode;
};

const SidebarProvider = ({
                             children,
                         }: Props) => {
    const [visible, setVisible] = useState(false);
    const [sidebarContents, setSidebarContents] = useState<SidebarContentsType>({type: 'empty'});
    const timerRef = useRef<number>();

    const handleShowSidebar = (action: SidebarContentsType) => {
        setSidebarContents(action);
        setVisible(true);
        clearTimeout(timerRef.current);
    };

    const handleHideSidebar = () => {
        setVisible(false);
        timerRef.current = window.setTimeout(() => {
            setSidebarContents({type: 'empty'});
        }, 400);
    };

    return (
        <SidebarContext.Provider value={{showSidebar: handleShowSidebar, hideSidebar: handleHideSidebar}}>
            {children}
            <Sidebar
                visible={visible}
                contents={sidebarContents}
            />
        </SidebarContext.Provider>
    );
};

export default SidebarProvider;
