import React, {useCallback, useContext, useRef} from 'react';
import LoadingBar from 'react-top-loading-bar';
import styles from "./LoaderProvider.module.css";
import {useHistory, Prompt} from "react-router-dom";

type LoadingBarRef = {
    continuousStart(startingValue?: number, refreshRate?: number): void;
    complete(): void;
};

type LoaderContextType = {
    startLoading: () => void;
    stopLoading: (skipScrollToTop?: boolean) => void;
};

const LoaderContext = React.createContext<LoaderContextType>({
    startLoading: () => null,
    stopLoading: () => null,
});

type Props = {
    children: React.ReactNode;
    contentRef: React.RefObject<HTMLDivElement>;
};

const LoaderProvider = ({
                            children,
                            contentRef,
                        }: Props) => {
    const loadingRef = useRef<LoadingBarRef>(null);
    const scrollPositions = useRef<{[key: string]: number | undefined}>({});
    const history = useHistory();

    const handleStartLoading = useCallback(
        () => {
            loadingRef.current?.continuousStart(50);
        },
        []
    );

    const handleStopLoading = useCallback(
        (skipScrollToTop?: boolean) => {
            loadingRef.current?.complete();

            if (!skipScrollToTop) {
                contentRef.current?.scrollTo(0, 0);

                if (history.location.key) {
                    const scrollPosition = scrollPositions.current[history.location.key];
                    if (scrollPosition) {
                        contentRef.current?.scrollTo(0, scrollPosition);
                    }
                }
            }
        },
        [contentRef, history]
    );

    const storeScrollPosition = () => {
        if (history.location.key) {
            scrollPositions.current[history.location.key] = contentRef.current?.scrollTop;
        }
    };

    return (
        <LoaderContext.Provider value={{startLoading: handleStartLoading, stopLoading: handleStopLoading}}>
            <Prompt
                message={() => {
                    storeScrollPosition();
                    return true;
                }}
            />
            <LoadingBar
                ref={loadingRef}
                shadow={false}
                waitingTime={400}
                className={styles.wrapper}
            />
            {children}
        </LoaderContext.Provider>
    );
};

export const useLoader = () => {
    return useContext(LoaderContext);
};

export default LoaderProvider;
