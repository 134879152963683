import React from 'react';
import styles from "./ChooseCompanyScreen.module.css";
import {H1} from "../../components/titles/titles";

type Props = {
    companies: Company[];
    onSelect: (companyId: number) => void;
};

const ChooseCompanyScreen = ({
                                 companies,
                                 onSelect,
                             }: Props) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.inner}>
                <div className={styles.titleWrapper}>
                    <H1>Choose portal</H1>
                </div>
                <div className={styles.tileBarWrapper}>
                    {companies.map(company => (
                        <div className={styles.tileBarCol} key={company.id} onClick={() => onSelect(company.id)}>
                            <div className={styles.tile}>
                                <div style={{backgroundImage: company.logoUrl ? `url(${company.logoUrl})` : undefined}} className={styles.companyLogo}/>
                                <div className={styles.companyName}>{company.name}</div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

export default ChooseCompanyScreen;
