import React, {useContext, useState} from 'react';
import styles from "./Modal.module.css";
import innerStyles from "./ModalUploadSmsFileContainer.module.css";
import LoadingButton from "../Button/LoadingButton";
import Textfield from "../Textfield/Textfield";
import Checkbox from "../Checkbox/Checkbox";
import FileUpload from "../FileUpload/FileUpload";
import {AppContext, handleDownloadTemplatePaylinksFile, uploadPaylinksFile} from "../../services";
import {useHistory} from "react-router-dom";
import Radios from "../Radios/Radios";
import Button from "../Button/Button";
import TextfieldDate from "../TextfieldDate/TextfieldDate";
import moment from "moment/moment";

type Props = {
    onClose: () => void;
};

const getSteps = (documentType: string): ('step1' | 'step2' | 'step3' | 'step4' | 'step5' | 'step6')[] => {
    if (documentType === 'Contribution') {
        return [
            "step1",
            "step2",
            "step4",
            "step5",
            "step6",
        ];
    }

    return [
        "step1",
        "step2",
        "step3",
        "step4",
        "step5",
        "step6",
    ];
};

const ModalUploadPaylinksFileContainer = ({
                                              onClose,
                                          }: Props) => {
    const history = useHistory();
    const {companies, currentCompanyId} = useContext(AppContext);
    const company = companies.find(company => company.id === currentCompanyId);
    const [screen, setScreen] = useState<'step1' | 'step2' | 'step3' | 'step4' | 'step5' | 'step6'>('step1');

    //Step 1
    const [senderId, setSenderId] = useState<string>(company!.paylinkSenders.length === 1 ? String(company!.paylinkSenders[0].id) : '');

    //Step 2
    const [documentType, setDocumentType] = useState<string>('');

    //Step 3
    const [dueDate, setDueDate] = useState<string>('');

    //Step 4
    const [expiryDate, setExpiryDate] = useState<string>('');

    //Step 5
    const [channel, setChannel] = useState<string>('');

    //Step 6
    const [file, setFile] = useState<File>();
    const [reference, setReference] = useState<string>('');
    const [mailWhenComplete, setMailWhenComplete] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState('');

    const steps = getSteps(documentType);
    const step = steps.indexOf(screen) + 1;
    const previousScreen = steps[step - 2];
    const nextScreen = steps[step];

    switch (screen) {
        case "step1": {
            const disabled = !senderId;

            const handleClick = () => {
                if (disabled) return;
                setScreen(nextScreen);
            };

            return (
                <div className={styles.modal}>
                    <div className={styles.title}>
                        {step}. Choose the beneficiary account for which you want to
                        generate paylinks
                    </div>
                    <div className={styles.body}>
                        <Radios
                            options={company!.paylinkSenders.map(paylinksSender => ({
                                key: String(paylinksSender.id),
                                value: (
                                    <span>{paylinksSender.name}<br/>{paylinksSender.senderContractNumber}<br/>{paylinksSender.bankAccount}</span>
                                ),
                            }))}
                            value={senderId}
                            setValue={setSenderId}
                            border
                        />
                    </div>
                    <Button
                        label="Next"
                        onClick={handleClick}
                        backgroundColor="green"
                        disabled={disabled}
                    />
                </div>
            );
        }
        case "step2": {
            const disabled = !documentType;

            const handleClick = () => {
                if (disabled) return;
                setScreen(nextScreen);
            };

            return (
                <div className={styles.modal}>
                    <div className={styles.title}>
                        {step}. The payment links I will create concern the following
                        type of payment
                    </div>
                    <div className={styles.body}>
                        <Radios
                            options={[
                                {key: 'Invoice', value: 'Invoice'},
                                {key: 'Reminder', value: 'Reminder'},
                                {key: 'Contribution', value: 'Contribution'},
                            ]}
                            value={documentType}
                            setValue={setDocumentType}
                            border
                        />
                    </div>
                    <div className={styles.buttonBar}>
                        <Button
                            label="Previous"
                            onClick={() => setScreen(previousScreen)}
                            backgroundColor="white"
                        />
                        <Button
                            label="Next"
                            onClick={handleClick}
                            backgroundColor="green"
                            disabled={disabled}
                        />
                    </div>
                </div>
            );
        }
        case "step3": {
            const disabled = !dueDate;

            const handleClick = () => {
                if (disabled) return;
                setScreen(nextScreen);
            };

            let usedValue = undefined;
            const momentDate = moment(dueDate);
            if (momentDate.isValid()) {
                usedValue = momentDate.toDate();
            }

            const handleSetValue = (value: Date | undefined) => {
                const newValue = value ? moment(value).format('YYYY-MM-DD') : undefined;
                setDueDate(newValue || '');
                setExpiryDate('');
            };

            return (
                <div className={styles.modal}>
                    <div className={styles.title}>
                        {step}. Due date that applies to all payment links
                    </div>
                    <div className={styles.body}>
                        <TextfieldDate
                            title="Due date"
                            hideLabel
                            value={usedValue}
                            setValue={handleSetValue}
                            border
                            minimumDate={moment().add(1, 'days').toDate()}
                            maximumDate={moment().add(90, 'days').toDate()}
                        />
                    </div>
                    <div className={styles.buttonBar}>
                        <Button
                            label="Previous"
                            onClick={() => setScreen(previousScreen)}
                            backgroundColor="white"
                        />
                        <Button
                            label="Next"
                            onClick={handleClick}
                            backgroundColor="green"
                            disabled={disabled}
                        />
                    </div>
                </div>
            );
        }
        case "step4": {
            const disabled = !expiryDate;

            const handleClick = () => {
                if (disabled) return;
                setScreen(nextScreen);
            };

            let usedValue = undefined;
            const momentDate = moment(expiryDate);
            if (momentDate.isValid()) {
                usedValue = momentDate.toDate();
            }

            const handleSetValue = (value: Date | undefined) => {
                const newValue = value ? moment(value).format('YYYY-MM-DD') : undefined;
                setExpiryDate(newValue || '');
            };

            return (
                <div className={styles.modal}>
                    <div className={styles.title}>
                        {step}. The payment links will need to remain valid until
                    </div>
                    <div className={styles.body}>
                        <TextfieldDate
                            title="Expiration date"
                            hideLabel
                            value={usedValue}
                            setValue={handleSetValue}
                            border
                            minimumDate={moment(dueDate || undefined).add(1, 'days').toDate()}
                            maximumDate={moment().add(90, 'days').toDate()}
                        />
                    </div>
                    <div className={styles.buttonBar}>
                        <Button
                            label="Previous"
                            onClick={() => setScreen(previousScreen)}
                            backgroundColor="white"
                        />
                        <Button
                            label="Next"
                            onClick={handleClick}
                            backgroundColor="green"
                            disabled={disabled}
                        />
                    </div>
                </div>
            );
        }
        case "step5": {
            const disabled = !documentType;

            const handleClick = () => {
                if (disabled) return;
                setScreen(nextScreen);
            };

            const channels = company!.paylinkSenders.find(s => String(s.id) === senderId)!.channels;

            return (
                <div className={styles.modal}>
                    <div className={styles.title}>
                        {step}. Choose channel
                    </div>
                    <div className={styles.body}>
                        <Radios
                            options={channels.map(channel => ({
                                key: channel,
                                value: channel,
                            }))}
                            value={channel}
                            setValue={setChannel}
                            border
                        />
                    </div>
                    <div className={styles.buttonBar}>
                        <Button
                            label="Previous"
                            onClick={() => setScreen(previousScreen)}
                            backgroundColor="white"
                        />
                        <Button
                            label="Next"
                            onClick={handleClick}
                            backgroundColor="green"
                            disabled={disabled}
                        />
                    </div>
                </div>
            );
        }
        case "step6": {
            const disabled = !file;

            const handleSubmit = async () => {
                if (disabled) return;

                try {
                    const data = await uploadPaylinksFile({
                        file: file!,
                        companyId: currentCompanyId!,
                        mailWhenComplete: mailWhenComplete,
                        reference: reference,

                        sender: senderId,
                        documentType: documentType,
                        dueDate: documentType !== 'Contribution' ? dueDate : '',
                        expiryDate: expiryDate,
                        channel: channel,
                    });

                    history.push(`/paylinks-files/${data.id}`);
                    onClose();
                } catch (error) {
                    setErrorMessage(error.message || 'An error occurred. Refresh this page or try again later.');
                }
            };

            return (
                <div className={styles.modal}>
                    <div className={styles.title}>
                        {step}. Upload the meta data
                    </div>
                    <div className={styles.body}>
                        <div className={innerStyles.spacer}>
                            <FileUpload
                                file={file}
                                setFile={setFile}
                            />
                        </div>

                        <div className={innerStyles.spacer}>
                            <Button
                                label="Download template"
                                backgroundColor="white"
                                onClick={() => handleDownloadTemplatePaylinksFile(currentCompanyId!)}
                            />
                        </div>

                        <div className={innerStyles.spacer}>
                            <Textfield
                                value={reference}
                                setValue={setReference}
                                placeholder="Reference (optional)"
                                border
                            />
                        </div>

                        <div className={innerStyles.notificationWrapper}>
                            <div className={innerStyles.notificationCheckboxWrapper}
                                 onClick={() => setMailWhenComplete(mailWhenComplete => !mailWhenComplete)}>
                                <Checkbox isChecked={mailWhenComplete}/>
                            </div>
                            <div className={innerStyles.notificationTextWrapper}>Receive an e-mail once the file is
                                validated
                            </div>
                        </div>

                    </div>

                    {errorMessage && (
                        <div className={styles.errorWrapper}>
                            {errorMessage}
                        </div>
                    )}
                    <div className={styles.buttonBar}>
                        <Button
                            label="Previous"
                            onClick={() => setScreen(previousScreen)}
                            backgroundColor="white"
                        />
                        <LoadingButton
                            label="Upload file"
                            onClick={handleSubmit}
                            backgroundColor="green"
                            disabled={disabled}
                        />
                    </div>
                </div>
            );
        }
    }
};

export default ModalUploadPaylinksFileContainer;
