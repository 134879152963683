import React, {useState} from 'react';
import {H2} from "../titles/titles";
import styles from './SidebarForm.module.css';
import LoadingButton from "../Button/LoadingButton";

type Props = {
    title?: string;
    children: React.ReactNode;
    buttonLabel?: string;
    onSubmit: () => void;
};

const SidebarForm = ({
                         title,
                         children,
                         buttonLabel = 'Confirm',
                         onSubmit,
                     }: Props) => {
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async () => {
        try {
            setErrorMessage('');
            await onSubmit();
        } catch (errorMessage) {
            setErrorMessage(errorMessage);
        }
    };

    return (
        <form className={styles.form} onSubmit={e => e.preventDefault()}>
            {title && (
                <div className={styles.title}>
                    <H2>{title}</H2>
                </div>
            )}
            {children}
            <div className={styles.filler}/>
            {errorMessage && (
                <div className={styles.errorWrapper}>
                    {errorMessage}
                </div>
            )}
            <div className={styles.buttonWrapper}>
                <LoadingButton
                    label={buttonLabel}
                    onClick={handleSubmit}
                />
            </div>
        </form>
    );
};

export default SidebarForm;
