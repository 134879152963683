import React from 'react';
import styles from "./SidebarFilters.module.css";

type Props = {
    left: React.ReactNode;
    right: React.ReactNode;
};

const SidebarFilterRow = ({
                              left,
                              right,
                          }: Props) => {
    return (
        <div className={styles.filterRow}>
            <div className={styles.filterCol}>
                {left}
            </div>
            <div className={styles.filterCol}>
                {right}
            </div>
        </div>
    );
};

export default SidebarFilterRow;
