import React, {useContext, useEffect, useState} from 'react';
import HomeScreen from "./HomeScreen";
import {useLoader} from "../../components/Loader/LoaderProvider";
import {AppContext, fetchHome} from "../../services";
import moment from 'moment';
import {DEFAULT_GROUP_BY as TRANSACTIONS_DEFAULT_GROUP_BY} from "../../components/TransactionsChart/TransactionsChart";
import {DEFAULT_GROUP_BY as PAYMENT_SPEED_DEFAULT_GROUP_BY} from "../../components/PaymentSpeedChart/PaymentSpeedChart";
import {useAlert} from "react-alert";

const HomeScreenWrapper = () => {
    const {startLoading, stopLoading} = useLoader();
    const {currentCompanyId} = useContext(AppContext);
    const [transactionsChartData, setTransactionsChartData] = useState<StatsSuccessfulTransactionsResponse>([]);
    const [paymentSpeedChartData, setPaymentSpeedChartData] = useState<StatsPaymentSpeedResponse>([0, 0, 0, 0, 0, 0]);
    const [firstTransactionDate, setFirstTransactionDate] = useState<string>('');
    const alert = useAlert();

    useEffect(
        () => {
            (async () => {
                startLoading();

                try {
                    const data = await fetchHome(
                        TRANSACTIONS_DEFAULT_GROUP_BY,
                        moment().subtract(1, 'month').format("YYYY-MM-DD"),
                        moment().format("YYYY-MM-DD"),
                        PAYMENT_SPEED_DEFAULT_GROUP_BY,
                        moment().format("YYYY-MM-DD"),
                        currentCompanyId!
                    );

                    setTransactionsChartData(data.statsSuccessfulTransactions);
                    setPaymentSpeedChartData(data.statsPaymentSpeed);
                    setFirstTransactionDate(data.firstTransactionDate);
                } catch (error) {
                    alert.error('An error occurred. Refresh this page or try again later.');
                }

                stopLoading();
            })();
        },
        [currentCompanyId, startLoading, stopLoading, alert]
    );

    return (
        <HomeScreen
            transactionsChartData={transactionsChartData}
            setTransactionsChartData={setTransactionsChartData}
            paymentSpeedChartData={paymentSpeedChartData}
            setPaymentSpeedChartData={setPaymentSpeedChartData}
            firstTransactionDate={firstTransactionDate}
        />
    );
};

export default HomeScreenWrapper;
