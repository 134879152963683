import React from 'react';
import {DragDropContext, Draggable, Droppable, DropResult, ResponderProvided} from "react-beautiful-dnd";
import {CheckboxOption} from "./Checkboxes";

type Props = {
    items: {
        item: CheckboxOption;
        node: React.ReactNode;
    }[];
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
};

const CheckboxesDraggableWrapper = ({
                                        items,
                                        onDragEnd,
                                    }: Props) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {provided => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {items.map((item, index) => (
                            <Draggable key={item.item.key} draggableId={item.item.key} index={index}>
                                {provided => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        {item.node}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default CheckboxesDraggableWrapper;
