import React, {useContext, useEffect, useState} from 'react';
import styles from "./Modal.module.css";
import innerStyles from "./ModalUploadSmsFileContainer.module.css";
import LoadingButton from "../Button/LoadingButton";
import Textfield from "../Textfield/Textfield";
import Checkbox from "../Checkbox/Checkbox";
import FileUpload from "../FileUpload/FileUpload";
import {AppContext, fetchSmsFiles, handleDownloadTemplateSmsFile, uploadSmsFile, validSmsContents} from "../../services";
import {useHistory} from "react-router-dom";
import Radios from "../Radios/Radios";
import Button from "../Button/Button";
import TextfieldDate from "../TextfieldDate/TextfieldDate";
import moment from "moment/moment";
import TextAreaSms from "../Textarea/TextAreaSms";
import classNames from "classnames";
import TextfieldInline from "../TextfieldInline/TextfieldInline";

type Props = {
    onClose: () => void;
};

const getStepNumber = (screen: string, documentType: string): number => {
    let map: { [index: string]: number };

    if (documentType === 'Contribution') {
        map = {
            "step1": 1,
            "step2": 2,
            "step4": 3,
            "step5": 4,
            "step6": 5,
        };
    } else {
        map = {
            "step1": 1,
            "step2": 2,
            "step3": 3,
            "step4": 4,
            "step5": 5,
            "step6": 6,
        };
    }

    return map[screen];
};

const ModalUploadSmsFileContainer = ({
                                         onClose,
                                     }: Props) => {
    const history = useHistory();
    const {companies, currentCompanyId} = useContext(AppContext);
    const company = companies.find(company => company.id === currentCompanyId);
    const [screen, setScreen] = useState<'step1' | 'step2' | 'step3' | 'step4' | 'step5' | 'step6'>('step1');

    //Step 1
    const [senderId, setSenderId] = useState<string>(company!.smsSenders.length === 1 ? String(company!.smsSenders[0].id) : '');

    //Step 2
    const [documentType, setDocumentType] = useState<string>('');

    //Step 3
    const [dueDate, setDueDate] = useState<string>('');

    //Step 4
    const [expiryDate, setExpiryDate] = useState<string>('');

    //Step 5
    const [messageNL, setMessageNL] = useState<string>('');
    const [messageFR, setMessageFR] = useState<string>('');
    const [messageEN, setMessageEN] = useState<string>('');
    const [documentIdNbChars, setDocumentIdNbChars] = useState<number>(0);
    const [amountNbChars, setAmountNbChars] = useState<number>(0);

    //Step 6
    const [file, setFile] = useState<File>();
    const [reference, setReference] = useState<string>('');
    const [mailWhenComplete, setMailWhenComplete] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(
        () => {
            (async () => {
                const data = await fetchSmsFiles("", currentCompanyId!);
                const items = data.items.sort((a, b) => { return b.id - a.id; });

                if (items.length > 0) {
                    setMessageNL(items[0].messageNL || '');
                    setMessageFR(items[0].messageFR || '');
                    setMessageEN(items[0].messageEN || '');
                    setDocumentIdNbChars(items[0].documentIdNbChars || 0);
                    setAmountNbChars(items[0].amountNbChars || 0);
                }
            })();
        },
        [currentCompanyId]
    );

    switch (screen) {
        case "step1": {
            const disabled = !senderId;

            const handleClick = () => {
                if (disabled) return;
                setScreen('step2');
            };

            return (
                <div className={styles.modal}>
                    <div className={styles.title}>
                        {getStepNumber(screen, documentType)}. Choose the beneficiary account for which you want to
                        generate payment messages
                    </div>
                    <div className={styles.body}>
                        <Radios
                            options={company!.smsSenders.map(smsSender => ({
                                key: String(smsSender.id),
                                value: (
                                    <span>{smsSender.name}<br/>{smsSender.senderContractNumber}<br/>{smsSender.bankAccount}</span>
                                ),
                            }))}
                            value={senderId}
                            setValue={setSenderId}
                            border
                        />
                    </div>
                    <Button
                        label="Next"
                        onClick={handleClick}
                        backgroundColor="green"
                        disabled={disabled}
                    />
                </div>
            );
        }
        case "step2": {
            const disabled = !documentType;

            const handleClick = () => {
                if (disabled) return;

                if (documentType === 'Contribution') {
                    setScreen('step4');
                } else {
                    setScreen('step3');
                }
            };

            return (
                <div className={styles.modal}>
                    <div className={styles.title}>
                        {getStepNumber(screen, documentType)}. The payment messages I will send concern the following
                        type of payment
                    </div>
                    <div className={styles.body}>
                        <Radios
                            options={[
                                {key: 'Invoice', value: 'Invoice'},
                                {key: 'Reminder', value: 'Reminder'},
                                {key: 'Contribution', value: 'Contribution'},
                            ]}
                            value={documentType}
                            setValue={setDocumentType}
                            border
                        />
                    </div>
                    <div className={styles.buttonBar}>
                        <Button
                            label="Previous"
                            onClick={() => setScreen('step1')}
                            backgroundColor="white"
                        />
                        <Button
                            label="Next"
                            onClick={handleClick}
                            backgroundColor="green"
                            disabled={disabled}
                        />
                    </div>
                </div>
            );
        }
        case "step3": {
            const disabled = !dueDate;

            const handleClick = () => {
                if (disabled) return;
                setScreen('step4');
            };

            let usedValue = undefined;
            const momentDate = moment(dueDate);
            if (momentDate.isValid()) {
                usedValue = momentDate.toDate();
            }

            const handleSetValue = (value: Date | undefined) => {
                const newValue = value ? moment(value).format('YYYY-MM-DD') : undefined;
                setDueDate(newValue || '');
                setExpiryDate('');
            };

            return (
                <div className={styles.modal}>
                    <div className={styles.title}>
                        {getStepNumber(screen, documentType)}. Due date that applies to all payment invitations
                    </div>
                    <div className={styles.body}>
                        <TextfieldDate
                            title="Due date"
                            hideLabel
                            value={usedValue}
                            setValue={handleSetValue}
                            border
                            minimumDate={moment().add(1, 'days').toDate()}
                            maximumDate={moment().add(90, 'days').toDate()}
                        />
                    </div>
                    <div className={styles.buttonBar}>
                        <Button
                            label="Previous"
                            onClick={() => setScreen('step2')}
                            backgroundColor="white"
                        />
                        <Button
                            label="Next"
                            onClick={handleClick}
                            backgroundColor="green"
                            disabled={disabled}
                        />
                    </div>
                </div>
            );
        }
        case "step4": {
            const disabled = !expiryDate;

            const handleBack = () => {
                if (documentType === 'Contribution') {
                    setScreen('step2');
                } else {
                    setScreen('step3');
                }
            };

            const handleClick = () => {
                if (disabled) return;
                setScreen('step5');
            };

            let usedValue = undefined;
            const momentDate = moment(expiryDate);
            if (momentDate.isValid()) {
                usedValue = momentDate.toDate();
            }

            const handleSetValue = (value: Date | undefined) => {
                const newValue = value ? moment(value).format('YYYY-MM-DD') : undefined;
                setExpiryDate(newValue || '');
            };

            return (
                <div className={styles.modal}>
                    <div className={styles.title}>
                        {getStepNumber(screen, documentType)}. The payment links that will appear in the messages will
                        need to remain valid until
                    </div>
                    <div className={styles.body}>
                        <TextfieldDate
                            title="Expiration date"
                            hideLabel
                            value={usedValue}
                            setValue={handleSetValue}
                            border
                            minimumDate={moment(dueDate || undefined).add(1, 'days').toDate()}
                            maximumDate={moment().add(90, 'days').toDate()}
                        />
                    </div>
                    <div className={styles.buttonBar}>
                        <Button
                            label="Previous"
                            onClick={handleBack}
                            backgroundColor="white"
                        />
                        <Button
                            label="Next"
                            onClick={handleClick}
                            backgroundColor="green"
                            disabled={disabled}
                        />
                    </div>
                </div>
            );
        }
        case "step5": {
            const linkNbChars = company!.smsSenders.find(smsSender => String(smsSender.id) === senderId)!.linkNbChars

            const disabled: boolean =
                (!messageNL && !messageFR && !messageEN)
                || !validSmsContents(messageNL)
                || !validSmsContents(messageFR)
                || !validSmsContents(messageEN)
            ;

            const handleClick = () => {
                if (disabled) return;
                setScreen('step6');
            };

            return (
                <div className={styles.modal}>
                    <div className={styles.title}>
                        {getStepNumber(screen, documentType)}. This is the message I want to send
                    </div>
                    <div className={styles.body}>
                        <div className={classNames(styles.textareaWrapper, styles.small)}>
                            <div>&lt;Link&gt; must be present and counts for {linkNbChars} characters.</div>
                            <div>
                                <span>&lt;DocumentId&gt; counts for </span>
                                <TextfieldInline
                                    type="number"
                                    value={String(documentIdNbChars)}
                                    setValue={value => setDocumentIdNbChars(Math.max(0, parseInt(value)))}
                                />
                                <span> characters.</span>
                            </div>
                            <div>
                                <span>&lt;Amount&gt; counts for </span>
                                <TextfieldInline
                                    type="number"
                                    value={String(amountNbChars)}
                                    setValue={value => setAmountNbChars(Math.max(0, parseInt(value)))}
                                />
                                <span> characters.</span>
                            </div>
                        </div>
                        <div className={styles.textareaWrapper}>
                            <TextAreaSms
                                value={messageNL}
                                setValue={setMessageNL}
                                title="NL (optional)"
                                rows={3}
                                border
                                linkNbChars={linkNbChars}
                                documentIdNbChars={documentIdNbChars}
                                amountNbChars={amountNbChars}
                            />
                        </div>
                        <div className={styles.textareaWrapper}>
                            <TextAreaSms
                                value={messageFR}
                                setValue={setMessageFR}
                                title="FR (optional)"
                                rows={3}
                                border
                                linkNbChars={linkNbChars}
                                documentIdNbChars={documentIdNbChars}
                                amountNbChars={amountNbChars}
                            />
                        </div>
                        <div className={styles.textareaWrapper}>
                            <TextAreaSms
                                value={messageEN}
                                setValue={setMessageEN}
                                title="EN (optional)"
                                rows={3}
                                border
                                linkNbChars={linkNbChars}
                                documentIdNbChars={documentIdNbChars}
                                amountNbChars={amountNbChars}
                            />
                        </div>
                    </div>
                    <div className={styles.buttonBar}>
                        <Button
                            label="Previous"
                            onClick={() => setScreen('step4')}
                            backgroundColor="white"
                        />
                        <Button
                            label="Next"
                            onClick={handleClick}
                            backgroundColor="green"
                            disabled={disabled}
                        />
                    </div>
                </div>
            );
        }
        case "step6": {
            const disabled = !file;

            const handleSubmit = async () => {
                if (disabled) return;

                try {
                    const data = await uploadSmsFile({
                        file: file!,
                        companyId: currentCompanyId!,
                        mailWhenComplete: mailWhenComplete,
                        reference: reference,

                        sender: senderId,
                        documentType: documentType,
                        dueDate: documentType !== 'Contribution' ? dueDate : '',
                        expiryDate: expiryDate,
                        messageNL: messageNL,
                        messageFR: messageFR,
                        messageEN: messageEN,
                        documentIdNbChars: documentIdNbChars,
                        amountNbChars: amountNbChars,
                    });

                    history.push(`/sms-files/${data.id}`);
                    onClose();
                } catch (error) {
                    setErrorMessage(error.message || 'An error occurred. Refresh this page or try again later.');
                }
            };

            return (
                <div className={styles.modal}>
                    <div className={styles.title}>
                        {getStepNumber(screen, documentType)}. Upload the meta data
                    </div>
                    <div className={styles.body}>
                        <div className={innerStyles.spacer}>
                            <FileUpload
                                file={file}
                                setFile={setFile}
                            />
                        </div>

                        <div className={innerStyles.spacer}>
                            <Button
                                label="Download template"
                                backgroundColor="white"
                                onClick={() => handleDownloadTemplateSmsFile(currentCompanyId!)}
                            />
                        </div>

                        <div className={innerStyles.spacer}>
                            <Textfield
                                value={reference}
                                setValue={setReference}
                                placeholder="Reference (optional)"
                                border
                            />
                        </div>

                        <div className={innerStyles.notificationWrapper}>
                            <div className={innerStyles.notificationCheckboxWrapper}
                                 onClick={() => setMailWhenComplete(mailWhenComplete => !mailWhenComplete)}>
                                <Checkbox isChecked={mailWhenComplete}/>
                            </div>
                            <div className={innerStyles.notificationTextWrapper}>Receive an e-mail once the file is
                                validated
                            </div>
                        </div>

                    </div>

                    {errorMessage && (
                        <div className={styles.errorWrapper}>
                            {errorMessage}
                        </div>
                    )}
                    <div className={styles.buttonBar}>
                        <Button
                            label="Previous"
                            onClick={() => setScreen('step5')}
                            backgroundColor="white"
                        />
                        <LoadingButton
                            label="Upload file"
                            onClick={handleSubmit}
                            backgroundColor="green"
                            disabled={disabled}
                        />
                    </div>
                </div>
            );
        }
    }
};

export default ModalUploadSmsFileContainer;
