//Make sure width is large enough to fit title + sortable/filterable icons
export const documentColumns: Record<keyof DocumentTableType, TableHeaderType<DocumentTableType>> = {
    id: {
        key: 'id',
        title: 'Details',
        sortable: false,
        filterable: false,
        width: 45,
        visible: true,
        cannotHideColumn: true,
        style: {
            textAlign: 'center',
        },
    },
    documentId: {
        key: 'documentId',
        title: 'Document Id',
        sortable: true,
        filterable: 'text',
        operators: ['equals', 'contains'],
        width: 130,
        visible: true,
    },
    amount: {
        key: 'amount',
        title: 'Amount',
        sortable: true,
        filterable: 'number',
        filterableRange: true,
        width: 90,
        visible: true,
        style: {
            textAlign: 'right',
            justifyContent: 'flex-end',
        },
    },
    communication: {
        key: 'communication',
        title: 'Communication',
        sortable: false,
        filterable: 'text',
        operators: ['equals', 'contains'],
        width: 170,
        visible: true,
    },
    type: {
        key: 'type',
        title: 'Type',
        sortable: true,
        filterable: 'checkboxes',
        width: 130,
        visible: true,
    },
    status: {
        key: 'status',
        title: 'Status',
        sortable: true,
        filterable: 'checkboxes',
        width: 130,
        visible: true,
    },
    channel: {
        key: 'channel',
        title: 'Channel',
        sortable: true,
        filterable: 'checkboxes',
        width: 130,
        visible: true,
    },
    documentDate: {
        key: 'documentDate',
        title: 'Document date',
        sortable: true,
        filterable: 'date',
        filterableRange: true,
        width: 130,
        visible: false,
    },
    customerId: {
        key: 'customerId',
        title: 'Customer Id',
        sortable: true,
        filterable: 'text',
        operators: ['equals', 'contains'],
        width: 130,
        visible: false,
    },
    beneficiary: {
        key: 'beneficiary',
        title: 'Beneficiary',
        sortable: true,
        filterable: 'checkboxes',
        width: 130,
        visible: false,
    },
    beneficiaryIban: {
        key: 'beneficiaryIban',
        title: 'Beneficiary IBAN',
        sortable: true,
        filterable: 'checkboxes',
        width: 150,
        visible: false,
    },
};

export const transactionColumns: Record<keyof TransactionTableType, TableHeaderType<TransactionTableType>> = {
    transactionId: {
        key: 'transactionId',
        title: 'Details',
        sortable: false,
        filterable: false,
        width: 45,
        visible: true,
        cannotHideColumn: true,
        style: {
            textAlign: 'center',
        },
    },
    transactionDate: {
        key: 'transactionDate',
        title: 'Transaction date',
        sortable: true,
        filterable: 'date',
        filterableRange: true,
        width: 140,
        visible: true,
    },
    customerId: {
        key: 'customerId',
        title: 'Customer ID',
        sortable: true,
        filterable: 'text',
        operators: ['equals', 'contains'],
        width: 200,
        visible: true,
    },
    documentId: {
        key: 'documentId',
        title: 'Document ID',
        sortable: true,
        filterable: 'text',
        operators: ['equals', 'contains'],
        width: 130,
        visible: true,
    },
    amount: {
        key: 'amount',
        title: 'Amount',
        sortable: true,
        filterable: 'number',
        filterableRange: true,
        width: 90,
        visible: true,
        style: {
            textAlign: 'right',
            justifyContent: 'flex-end',
        },
    },
    communication: {
        key: 'communication',
        title: 'Communication',
        sortable: true,
        filterable: 'text',
        operators: ['equals', 'contains'],
        width: 170,
        visible: true,
    },
    status: {
        key: 'status',
        title: 'Status',
        sortable: true,
        filterable: 'checkboxes',
        width: 130,
        visible: true,
    },
    paymentMethod: {
        key: 'paymentMethod',
        title: 'Payment method',
        sortable: true,
        filterable: 'checkboxes',
        width: 200,
        visible: false,
    },
    channel: {
        key: 'channel',
        title: 'Channel',
        sortable: true,
        filterable: 'checkboxes',
        width: 130,
        visible: false,
    },
    beneficiaryIban: {
        key: 'beneficiaryIban',
        title: 'Beneficiary IBAN',
        sortable: true,
        filterable: 'checkboxes',
        width: 150,
        visible: false,
    },
    parentSenderName: {
        key: 'parentSenderName',
        title: 'Parent sender name',
        sortable: true,
        filterable: 'checkboxes',
        width: 170,
        visible: false,
    },
};

export const exportColumns: Record<keyof ExportTableType, TableHeaderType<ExportTableType>> = {
    dateCreated: {
        key: 'dateCreated',
        title: 'Date',
        sortable: true,
        filterable: false,
        width: 120,
        visible: true,
    },
    status: {
        key: 'status',
        title: 'Status',
        sortable: true,
        filterable: false,
        width: 85,
        visible: true,
    },
    totalRows: {
        key: 'totalRows',
        title: 'Results',
        sortable: true,
        filterable: false,
        width: 80,
        visible: true,
    },
    reference: {
        key: 'reference',
        title: 'Reference',
        sortable: true,
        filterable: 'text',
        width: 100,
        visible: true,
    },
    id: {
        key: 'id',
        title: 'Download',
        sortable: false,
        filterable: false,
        width: 70,
        visible: true,
        cannotHideColumn: true,
        style: {
            textAlign: 'center',
        },
    },
};

export const smsFileColumns: Record<keyof SmsFileTableType, TableHeaderType<SmsFileTableType>> = {
    id: {
        key: 'id',
        title: 'Details',
        sortable: false,
        filterable: false,
        width: 45,
        visible: true,
        cannotHideColumn: true,
        style: {
            textAlign: 'center',
        },
    },
    dateCreated: {
        key: 'dateCreated',
        title: 'Date',
        sortable: true,
        filterable: "date",
        filterableRange: true,
        width: 120,
        visible: true,
    },
    userName: {
        key: 'userName',
        title: 'Created by',
        sortable: false,
        filterable: "checkboxes",
        width: 200,
        visible: true,
    },
    status: {
        key: 'status',
        title: 'Status',
        sortable: true,
        filterable: "checkboxes",
        width: 85,
        visible: true,
    },
    totalRows: {
        key: 'totalRows',
        title: 'Rows',
        sortable: true,
        filterable: false,
        width: 80,
        visible: true,
    },
    reference: {
        key: 'reference',
        title: 'Reference',
        sortable: true,
        filterable: 'text',
        width: 400,
        visible: true,
    },
    messageNL: {
        isHelperColumn: true,
    },
    messageFR: {
        isHelperColumn: true,
    },
    messageEN: {
        isHelperColumn: true,
    },
    documentIdNbChars: {
        isHelperColumn: true,
    },
    amountNbChars: {
        isHelperColumn: true,
    },
};

export const paylinksFileColumns: Record<keyof PaylinksFileTableType, TableHeaderType<PaylinksFileTableType>> = {
    id: {
        key: 'id',
        title: 'Details',
        sortable: false,
        filterable: false,
        width: 45,
        visible: true,
        cannotHideColumn: true,
        style: {
            textAlign: 'center',
        },
    },
    dateCreated: {
        key: 'dateCreated',
        title: 'Date',
        sortable: true,
        filterable: "date",
        filterableRange: true,
        width: 120,
        visible: true,
    },
    userName: {
        key: 'userName',
        title: 'Created by',
        sortable: false,
        filterable: "checkboxes",
        width: 200,
        visible: true,
    },
    status: {
        key: 'status',
        title: 'Status',
        sortable: true,
        filterable: "checkboxes",
        width: 85,
        visible: true,
    },
    totalRows: {
        key: 'totalRows',
        title: 'Rows',
        sortable: true,
        filterable: false,
        width: 80,
        visible: true,
    },
    reference: {
        key: 'reference',
        title: 'Reference',
        sortable: true,
        filterable: 'text',
        width: 400,
        visible: true,
    },
};
