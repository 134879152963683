import React from 'react';
import styles from './Radios.module.css';
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

export interface RadiosProps {
    options: {
        key: string;
        value: React.ReactNode;
    }[];
    value: string;
    setValue: (value: string) => void;
    border?: boolean;
}

const Radios = ({
                    options,
                    value,
                    setValue,
                    border,
                }: RadiosProps) => {
    return (
        <div>
            {options.map(option => {
                const isChecked = value === option.key;

                const handleClick = () => {
                    if (isChecked) {
                        setValue('');
                    } else {
                        setValue(option.key);
                    }
                };

                return (
                    <div
                        className={classNames({
                            [styles.item]: true,
                            [styles.itemChecked]: isChecked,
                            [styles.withBorder]: border,
                        })}
                        key={option.key}
                        onClick={handleClick}
                    >
                        <div className={classNames({
                            [styles.icon]: true,
                            [styles.iconEmpty]: !isChecked,
                            [styles.iconChecked]: isChecked,
                        })}/>
                        <span className={styles.value}>{option.value}</span>
                    </div>
                )
            })}
        </div>
    );
};

export default Radios;
