import React, {useContext, useState} from 'react';
import SidebarForm from "../SidebarForm/SidebarForm";
import Textfield from "../Textfield/Textfield";
import styles from "./SidebarChangePassword.module.css";
import {AppContext, changePassword, SidebarContext} from "../../services";
import {useAlert} from "react-alert";

const SidebarChangePassword = () => {
    const {userId} = useContext(AppContext);
    const {hideSidebar} = useContext(SidebarContext);
    const alert = useAlert();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

    const handleSubmit = async () => {
        if (!currentPassword) {
            return Promise.reject('Enter your current password to continue.');
        }

        if (!newPassword) {
            return Promise.reject('Enter a new password to continue.');
        }

        if (newPassword !== newPasswordRepeat) {
            return Promise.reject('The repeated password is not identical to the new password.');
        }

        try {
            await changePassword(userId, currentPassword, newPassword);
            hideSidebar();
            alert.success('Your password has been reset successfully.');
        } catch (error) {
            return Promise.reject(typeof error === 'string' ? error : 'An error occurred. Refresh this page or try again later.');
        }
    };

    return (
        <SidebarForm title="Change your password" buttonLabel="Change password" onSubmit={handleSubmit}>
            <div className={styles.field}>
                <Textfield
                    title="Current password"
                    value={currentPassword}
                    setValue={setCurrentPassword}
                    type="password"
                    clearable
                    autoFocus
                />
            </div>
            <div className={styles.field}>
                <Textfield
                    title="New password"
                    value={newPassword}
                    setValue={setNewPassword}
                    type="password"
                    clearable
                />
            </div>
            <div className={styles.field}>
                <Textfield
                    title="Repeat new password"
                    value={newPasswordRepeat}
                    setValue={setNewPasswordRepeat}
                    type="password"
                    clearable
                />
            </div>
        </SidebarForm>
    );
};

export default SidebarChangePassword;
