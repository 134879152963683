import React, {useEffect, useState} from 'react';
import styles from './MenuLeft.module.css';
import classNamesBind from 'classnames/bind';
import {NavLink} from "react-router-dom";

const classNames = classNamesBind.bind(styles);

export type MenuLeftProps = {
    smsPage: boolean;
    paylinksPage: boolean;
};

const MenuLeft = ({
                      smsPage,
                      paylinksPage,
                  }: MenuLeftProps) => {
    const [collapsed, setCollapsed] = useState(() => {
        const menuCollapsed = localStorage.getItem('menuCollapsed');
        return menuCollapsed === 'true';
    });

    useEffect(
        () => {
            if (collapsed) {
                document.body.classList.add('menu-left-closed');
                document.body.classList.remove('menu-left-open');
            } else {
                document.body.classList.add('menu-left-open');
                document.body.classList.remove('menu-left-closed');
            }
        },
        [collapsed]
    );

    const handleSetCollapsed = () => {
        localStorage.setItem('menuCollapsed', String(!collapsed));
        setCollapsed(!collapsed);
    };

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.uncollapsed]: !collapsed,
            })}
        >
            <div className={styles.logoWrapper}/>

            <NavLink to="/home" className={styles.menuItem}>
                <div className={styles.activeHandler}/>
                <div className={classNames(styles.icon, styles.iconHome)}/>
                <div className={styles.menuItemTitle}>Home</div>
            </NavLink>
            {/*<NavLink to="/documents" className={styles.menuItem}>*/}
            {/*    <div className={styles.activeHandler}/>*/}
            {/*    <div className={classNames(styles.icon, styles.iconDocuments)}/>*/}
            {/*    <div className={styles.menuItemTitle}>Documents</div>*/}
            {/*</NavLink>*/}
            <NavLink to="/transactions" className={styles.menuItem}>
                <div className={styles.activeHandler}/>
                <div className={classNames(styles.icon, styles.iconTransactions)}/>
                <div className={styles.menuItemTitle}>Transactions</div>
            </NavLink>
            <NavLink to="/exports" className={styles.menuItem}>
                <div className={styles.activeHandler}/>
                <div className={classNames(styles.icon, styles.iconExports)}/>
                <div className={styles.menuItemTitle}>Exports</div>
            </NavLink>
            {smsPage && (
                <NavLink to="/sms-files" className={styles.menuItem}>
                    <div className={styles.activeHandler}/>
                    <div className={classNames(styles.icon, styles.iconSms)}/>
                    <div className={styles.menuItemTitle}>Send SMS</div>
                </NavLink>
            )}
            {paylinksPage && (
                <NavLink to="/paylinks-files" className={styles.menuItem}>
                    <div className={styles.activeHandler}/>
                    <div className={classNames(styles.icon, styles.iconPaylinks)}/>
                    <div className={styles.menuItemTitle}>Create Paylinks</div>
                </NavLink>
            )}

            <div className={styles.spacer}/>

            <NavLink to="/account" className={styles.menuItem}>
                <div className={styles.activeHandler}/>
                <div className={classNames(styles.icon, styles.iconUsers)}/>
                <div className={styles.menuItemTitle}>Account</div>
            </NavLink>
            <div className={styles.menuItem} onClick={handleSetCollapsed}>
                <div className={classNames(styles.icon, {
                    [styles.iconUncollapse]: collapsed,
                    [styles.iconCollapse]: !collapsed,
                })}/>
                <div className={styles.menuItemTitle}>Close menu</div>
            </div>

        </div>
    );
};

export default MenuLeft;
