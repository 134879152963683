import React from 'react';
import {H1} from "../../components/titles/titles";
import styles from "./SmsFileScreen.module.css";
import FieldsPane, {FieldsCol} from "../../components/FieldsPane/FieldsPane";
import Field from "../../components/Field/Field";
import ItemNoResults from "../../components/ItemNoResults/ItemNoResults";
import moment from "moment";
import SmsFileStatus from "../../components/SmsFileStatus/SmsFileStatus";
import ActionTile from "../../components/ActionTileBar/ActionTile";
import ActionTileBar from "../../components/ActionTileBar/ActionTileBar";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import SmsFileContentsTable from "./SmsFileContentsTable";

type Props = {
    paylinksFile: PaylinksFileType | null | undefined;
    onEdit: (() => void) | null;
    onDownloadOriginalFile: (() => void) | null;
    onDownloadProcessedFile: (() => void) | null;
    onProcessFile: (() => void) | null;
    onCancel: (() => void) | null;
};

const PaylinksFileScreen = ({
                           paylinksFile,
                           onEdit,
                           onDownloadOriginalFile,
                           onDownloadProcessedFile,
                           onProcessFile,
                           onCancel,
                       }: Props) => {
    return (
        <div>
            <div className={styles.titleWrapper}>
                <H1>Paylinks file</H1>
            </div>
            {paylinksFile && (
                <PaylinksFileScreenContents
                    paylinksFile={paylinksFile}
                    onEdit={onEdit}
                    onDownloadOriginalFile={onDownloadOriginalFile}
                    onDownloadProcessedFile={onDownloadProcessedFile}
                    onProcessFile={onProcessFile}
                    onCancel={onCancel}
                />)}
            {paylinksFile === null && <ItemNoResults/>}
        </div>
    );
};

type PaylinksFileScreenContentsProps = {
    paylinksFile: PaylinksFileType;
    onEdit: (() => void) | null;
    onDownloadOriginalFile: (() => void) | null;
    onDownloadProcessedFile: (() => void) | null;
    onProcessFile: (() => void) | null;
    onCancel: (() => void) | null;
};

const PaylinksFileScreenContents = ({
                                   paylinksFile,
                                   onEdit,
                                   onDownloadOriginalFile,
                                   onDownloadProcessedFile,
                                   onProcessFile,
                                   onCancel,
                               }: PaylinksFileScreenContentsProps) => {
    const {
        dateCreated,
        mailWhenComplete,
        reference,
        beneficiary,
        documentType,
        dueDate,
        expiryDate,
        rowsComplete,
        status,
        validateLog,
        processLog,
        processTimestamp,
        userName,
        channel,
    } = paylinksFile;

    return (
        <>
            <div className={styles.primaryFieldsWrapper}>
                <FieldsPane>
                    <FieldsCol>
                        <Field label="Date created" children={moment(dateCreated).format('DD/MM/YYYY HH:mm')}/>
                        {processTimestamp && <Field label="Date completed" children={moment(processTimestamp).format('DD/MM/YYYY HH:mm')}/>}
                        <Field label="Created by" children={userName}/>
                        <Field label="Send status mails" children={mailWhenComplete ? 'Yes' : 'No'}/>
                        <Field label="Rows complete" children={rowsComplete}/>
                    </FieldsCol>
                    <FieldsCol>
                        <div className={styles.statusRow}>
                            <SmsFileStatus status={status}/>
                        </div>
                        <PaylinksFileProgressBar paylinksFile={paylinksFile}/>
                    </FieldsCol>
                </FieldsPane>
            </div>
            <div className={styles.fieldsWrapper}>
                <FieldsPane>
                    <FieldsCol>
                        <Field label="Reference" children={reference}/>
                        <Field label="Document type" children={documentType}/>
                        {documentType !== 'Contribution' && <Field label="Due date" children={moment(dueDate).format('DD/MM/YYYY HH:mm')}/>}
                        <Field label="Expiration date" children={moment(expiryDate).format('DD/MM/YYYY HH:mm')}/>
                    </FieldsCol>
                    <FieldsCol>
                        <Field label="Beneficiary" children={beneficiary}/>
                        <Field label="Channel" children={channel}/>
                    </FieldsCol>
                </FieldsPane>
            </div>
            <div className={styles.titleWrapper}>
                <H1>Contents</H1>
            </div>
            <div className={styles.fileContentsWrapper}>
                <SmsFileContentsTable file={paylinksFile}/>
            </div>
            <div className={styles.titleWrapper}>
                <H1>Actions</H1>
            </div>
            <div className={styles.actionBarWrapper}>
                <ActionTileBar>
                    {onEdit && (
                        <ActionTile
                            onClick={onEdit}
                            title="Edit"
                            icon="iconEdit"
                        />
                    )}
                    {onDownloadOriginalFile && (
                        <ActionTile
                            onClick={onDownloadOriginalFile}
                            title="Download original file"
                            icon="iconDownloadOriginal"
                        />
                    )}
                    {onDownloadProcessedFile && (
                        <ActionTile
                            onClick={onDownloadProcessedFile}
                            title="Download processed file"
                            icon="iconDownloadProcessed"
                        />
                    )}
                    {onProcessFile && (
                        <ActionTile
                            onClick={onProcessFile}
                            title="Process file"
                            icon="iconProcess"
                        />
                    )}
                    {onCancel && (
                        <ActionTile
                            onClick={onCancel}
                            title="Cancel"
                            icon="iconCancel"
                        />
                    )}
                </ActionTileBar>
            </div>
            {Boolean(validateLog?.length || processLog?.length) && (
                <>
                    <div className={styles.titleWrapper}>
                        <H1>Log</H1>
                    </div>
                    <div className={styles.fieldsWrapper}>
                        <FieldsPane>
                            <ul className={styles.list}>
                                {[...(validateLog || []), ...(processLog || [])].map((logItem, i) => (
                                    <li key={i}>{logItem}</li>
                                ))}
                            </ul>
                        </FieldsPane>
                    </div>
                </>
            )}
        </>
    );
};

type PaylinksFileProgressBarProps = {
    paylinksFile: PaylinksFileType;
}

const PaylinksFileProgressBar = ({paylinksFile}: PaylinksFileProgressBarProps) => {
    const {
        status,
        totalRows,
        rowsComplete,
    } = paylinksFile;

    if (!["VALIDATING", "RUNNING"].includes(status)) {
        return null;
    }

    let widthPercentage = 5; //Always show some progress

    if (totalRows && totalRows > 0 && rowsComplete) {
        widthPercentage = rowsComplete / totalRows * 100;
        widthPercentage = Math.min(widthPercentage, 100);
    }

    return (
        <div className={styles.progressBarContainer}>
            <ProgressBar widthPercentage={widthPercentage}/>
        </div>
    );
};

export default PaylinksFileScreen;
