import React from 'react';
import styles from "./SidebarFilters.module.css";
import {H2} from "../titles/titles";

type Props = {
    title: string;
    children: React.ReactNode;
};

const SidebarFilterContainer = ({
                                    title,
                                    children,
                                }: Props) => {
    return (
        <div className={styles.filterWrapper}>
            <div className={styles.titleWrapper}>
                <H2>{title}</H2>
            </div>
            {children}
        </div>
    );
};

export default SidebarFilterContainer;