import React, {useContext} from 'react';
import styles from "./Modal.module.css";
import LoadingButton from "../Button/LoadingButton";
import {cancelPaylinksFile, RefreshContext} from "../../services";

type Props = {
    onClose: () => void;
    paylinksFileId: number;
};

const ModalCancelPaylinksFileContainer = ({
                                         onClose,
                                         paylinksFileId,
                                     }: Props) => {
    const {refresh} = useContext(RefreshContext);

    const handleSubmit = async () => {
        await cancelPaylinksFile(paylinksFileId);
        onClose();
        refresh(`paylinksFile-${paylinksFileId}`);
    };

    return (
        <div className={styles.modal}>
            <div className={styles.title}>Are you sure you want to cancel this paylinks file?</div>
            <div className={styles.body}/>
            <LoadingButton label="Cancel file" onClick={handleSubmit} backgroundColor="red"/>
        </div>
    );
};

export default ModalCancelPaylinksFileContainer;
