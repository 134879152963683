import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    AppContext,
    downloadOriginalPaylinksFile,
    downloadProcessedPaylinksFile,
    fetchPaylinksFile,
    ModalContext,
    retrieveFilenameFromHeaders,
    sendFileToBrowser,
    SidebarContext
} from "../../services";
import {useGenericItemLoader} from "../../hooks/useGenericItemLoader";
import PaylinksFileScreen from "./PaylinksFileScreen";

const PaylinksFileScreenWrapper = () => {
    const [paylinksFile, setPaylinksFile] = useState<PaylinksFileType | null>();
    const paylinksFileInitialDownload = useGenericItemLoader(fetchPaylinksFile, 'paylinksFile');
    const {currentCompanyId, userId} = useContext(AppContext);
    const {showModal} = useContext(ModalContext);
    const {showSidebar} = useContext(SidebarContext);
    const timeoutRef = useRef<number>();

    //Keep shown paylinksFile in state, so we can replace it with an updated version later
    useEffect(
        () => {
            setPaylinksFile(paylinksFileInitialDownload);
        },
        [paylinksFileInitialDownload]
    );


    useEffect(
        () => {
            const statusListToRenew: SmsFileStatusType[] = ["NEW", "VALIDATING", "RUNNING"];

            //Skip if we're not in a status that needs renewing
            if (!paylinksFile?.status || !statusListToRenew.includes(paylinksFile.status)) return;

            //Fetch data and make sure to set a new paylinksFile. That will re-trigger this effect
            timeoutRef.current = window.setTimeout(async () => {
                try {
                    const data = await fetchPaylinksFile(String(paylinksFile.id), currentCompanyId!);
                    setPaylinksFile(data);
                } catch (error) {
                    //We don't have new data but reset paylinksFile so effect is re-run
                    setPaylinksFile({...paylinksFile});
                }
            }, 2000);

            //Clear timeout if we navigate away from page while timeout is still running
            return () => {
                clearTimeout(timeoutRef.current);
            };
        },
        [paylinksFile, currentCompanyId]
    );

    const handleDownloadOriginalFile = !paylinksFile ? null : async () => {
        const response = await downloadOriginalPaylinksFile(paylinksFile.id, currentCompanyId!);

        //fallback filename if no filename from server is received
        const filename = retrieveFilenameFromHeaders(response.headers, `Paylinks file ${paylinksFile.id}.xlsx`);

        const blob = await response.blob();
        sendFileToBrowser(filename, blob);
    };

    const handleDownloadProcessedFile = !paylinksFile?.hasProcessedFile ? null : async () => {
        const response = await downloadProcessedPaylinksFile(paylinksFile.id, currentCompanyId!);

        //fallback filename if no filename from server is received
        const filename = retrieveFilenameFromHeaders(response.headers, `Paylinks file ${paylinksFile.id}.xlsx`);

        const blob = await response.blob();
        sendFileToBrowser(filename, blob);
    };

    const handleProcessFile = (paylinksFile?.status !== 'VALIDATED' || userId !== paylinksFile.userId) ? null : async () => {
        showModal({
            type: 'processPaylinksFile',
            paylinksFileId: paylinksFile.id,
            mailWhenComplete: paylinksFile?.mailWhenComplete,
        });
    };

    const handleCancel = (!paylinksFile || paylinksFile.status === 'DONE' || paylinksFile.status === 'CANCELLED' || userId !== paylinksFile.userId) ? null : () => {
        showModal({
            type: 'cancelPaylinksFile',
            paylinksFileId: paylinksFile.id,
        });
    };

    let handleEdit = null;
    if (userId === paylinksFile?.userId) {
        if (paylinksFile?.status === "VALIDATED" || paylinksFile?.status === "ERROR") {
            handleEdit = () => {
                showSidebar({
                    type: 'editPaylinksFile',
                    paylinksFileId: paylinksFile.id,
                    reference: paylinksFile.reference,
                    documentType: paylinksFile.documentType,
                    dueDate: paylinksFile.dueDate,
                    expiryDate: paylinksFile.expiryDate,
                    channel: paylinksFile.channel,
                    senderId: paylinksFile.senderId,
                });
            };
        } else if (paylinksFile?.status === "DONE" || paylinksFile?.status === "CANCELLED") {
            handleEdit = () => {
                showSidebar({
                    type: 'editPaylinksFileReference',
                    paylinksFileId: paylinksFile.id,
                    reference: paylinksFile.reference,
                });
            };
        }
    }

    return (
        <PaylinksFileScreen
            paylinksFile={paylinksFile}
            onEdit={handleEdit}
            onDownloadOriginalFile={handleDownloadOriginalFile}
            onDownloadProcessedFile={handleDownloadProcessedFile}
            onProcessFile={handleProcessFile}
            onCancel={handleCancel}
        />
    );
};

export default PaylinksFileScreenWrapper;
