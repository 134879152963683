import React, {Dispatch} from 'react';
import Checkboxes from "../Checkboxes/Checkboxes";
import {FiltersReducerAction, FiltersType} from "./SidebarFilters";

type Props<T> = {
    headerConfig: TableHeaderNormalType<T>;
    options: {
        key: string;
        value: string;
    }[];
    activeFilters: FiltersType;
    dispatch: Dispatch<FiltersReducerAction>;
};

const SidebarFilterCheckboxes = <T, >({
                                          headerConfig,
                                          options,
                                          activeFilters,
                                          dispatch,
                                      }: Props<T>) => {
    const handleSetValue = (value: string[]) => {
        dispatch({
            type: 'setValue',
            key: `${headerConfig.key}`,
            value: value,
        });
    };

    let checkboxValues: string[] = [];

    const valuesFromFilter = activeFilters[`${headerConfig.key}`];
    if (Array.isArray(valuesFromFilter)) {
        checkboxValues = valuesFromFilter;
    } else if (valuesFromFilter) {
        checkboxValues = [valuesFromFilter] as string[];
    }

    return (
        <Checkboxes
            options={options}
            value={checkboxValues}
            setValue={handleSetValue}
        />
    );
};

export default SidebarFilterCheckboxes;
