import React from 'react';
import styles from "./ActionTileBar.module.css";

type Props = {
    onClick: () => void;
    title: string;
    icon: 'iconPassword' | 'iconUserManagement' | 'iconSwitchPortal' | 'iconEdit' | 'iconDownloadOriginal' | 'iconDownloadProcessed' | 'iconProcess' | 'iconCancel';
};

const ActionTile = ({
                        onClick,
                        title,
                        icon,
                    }: Props) => {
    return (
        <div className={styles.col}>
            <div className={styles.tileWrapper} onClick={onClick}>
                <div className={`${styles.icon} ${styles[icon]}`}/>
                <div className={styles.label}>{title}</div>
            </div>
        </div>
    );
};

export default ActionTile;