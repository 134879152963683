import React from 'react';
import styles from './RadiosBar.module.css';
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

export interface RadiosProps {
    options: {
        key: string;
        value: string;
    }[];
    value: string;
    setValue: (value: string) => void;
}

const RadiosBar = ({
                    options,
                    value,
                    setValue,
                }: RadiosProps) => {
    return (
        <div className={styles.wrapper}>
            {options.map(option => {
                const isChecked = value === option.key;

                return (
                    <div
                        className={classNames({
                            [styles.item]: true,
                            [styles.itemChecked]: isChecked,
                        })}
                        key={option.key}
                        onClick={() => setValue(option.key)}
                    >
                        <span>{option.value}</span>
                    </div>
                )
            })}
        </div>
    );
};

export default RadiosBar;
