import React from 'react';
import TransactionScreen from "./TransactionScreen";
import {fetchTransaction} from "../../services";
import {useGenericItemLoader} from "../../hooks/useGenericItemLoader";

const TransactionScreenWrapper = () => {
    const transaction = useGenericItemLoader(fetchTransaction, 'transaction');

    return (
        <TransactionScreen transaction={transaction}/>
    );
};

export default TransactionScreenWrapper;
