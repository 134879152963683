import React, {useState} from 'react';
import styles from './TextfieldYear.module.css';
import moment from 'moment';
import CalendarYear from "../CalendarYear/CalendarYear";

export interface TextfieldDateProps {
    value: Date;
    setValue: (value: Date | undefined) => void;
    minDate: Date;
}

const TextfieldYear = ({
                           value,
                           setValue,
                           minDate,
                       }: TextfieldDateProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSetValue = (value: Date | undefined) => {
        setValue(value);
        setIsOpen(false);
    };

    return (
        <div>
            <div className={styles.wrapper} onClick={() => setIsOpen(!isOpen)}>
                <span>{moment(value).format('YYYY')}</span>
                <div className={`${styles.icon} ${styles.iconCalendar}`}/>
                {isOpen && (
                    <>
                        <div className={styles.backdrop}/>
                        <div className={styles.calendar}>
                            <CalendarYear
                                value={value}
                                setValue={handleSetValue}
                                minDate={minDate}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default TextfieldYear;
