import React from 'react';
import {H2} from "../titles/titles";
import styles from "./TableNoResults.module.css";

const TableNoResults = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <H2>No results found</H2>
            </div>
            <div className={styles.description}>
                The filter you applied, returned no results. Edit your filter or remove it to find what you are looking for.
            </div>
        </div>
    );
};

export default TableNoResults;
