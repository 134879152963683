import React, {useCallback, useState} from 'react';
import {ModalContext} from "../../services";
import ModalContainer from "./ModalContainer";

type Props = {
    children: React.ReactNode;
};

const ModalProvider = ({
                           children,
                       }: Props) => {
    const [visible, setVisible] = useState(false);
    const [modalContents, setModalContents] = useState<ModalContentsType>({type: 'empty'});

    const handleShowModal = useCallback((action: ModalContentsType) => {
        setModalContents(action);
        setVisible(true);
    }, []);

    const handleHideModal = useCallback(() => {
        setVisible(false);
        setModalContents({type: 'empty'});
    }, []);

    return (
        <ModalContext.Provider value={{showModal: handleShowModal, hideModal: handleHideModal}}>
            {children}
            <ModalContainer visible={visible} onClose={handleHideModal} modalContents={modalContents}/>
        </ModalContext.Provider>
    );
};

export default ModalProvider;
