import React from 'react';
import styles from "./SmsFileStatus.module.css";
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

type Props = {
    status: SmsFileStatusType;
};

const statusLabels: { [index in SmsFileStatusType]: string; } = {
    NEW: 'NEW',
    VALIDATING: 'VALIDATING',
    VALIDATED: 'VALIDATED',
    RUNNING: 'RUNNING',
    DONE: 'DONE',
    CANCELLED: 'CANCELLED',
    ERROR: 'ERROR',
};

const SmsFileStatus = ({
                            status,
                        }: Props) => {
    return (
        <div className={classNames('wrapper', `wrapper-${status}`)}>
            <div className={styles.icon}/>
            <div>{statusLabels[status]}</div>
        </div>
    );
};

export default SmsFileStatus;
