import React, {useContext} from 'react';
import {H2} from "../titles/titles";
import styles from "./TableNoResults.module.css";
import {AppContext, handleDownloadTemplatePaylinksFile} from "../../services";
import TableNoResults from "./TableNoResults";
import {useSavedQueryParts} from "../../hooks/useQueryParts";

const PaylinksFilesTableNoResults = () => {
    const {currentCompanyId} = useContext(AppContext);
    const {parsedQuery} = useSavedQueryParts();

    if (Object.keys(parsedQuery).length > 0) {
        return <TableNoResults/>;
    }

    const handleClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        await handleDownloadTemplatePaylinksFile(currentCompanyId!);
    };

    // eslint-disable-next-line
    const link = <a href="#" onClick={handleClick}>download this template</a>;

    return (
        <div className={styles.smsWrapper}>
            <div className={styles.titleWrapper}>
                <H2>Get started!</H2>
            </div>
            <div className={styles.description}>
                To create your first payment links via POM, {link}.<br/><br/>
                On the 'Data' tab you can enter the details of the individual payment invitations. Then create a shipping job by clicking the '+' button in the top right corner. Go through the steps and upload the file in the final step, after which the file will be validated. In case of errors you will have to correct or delete the relevant records. If the file was successfully validated, and has reached the 'Validated' status, you can process the file to create the links.
            </div>
        </div>
    );
};

export default PaylinksFilesTableNoResults;
