import React from 'react';
import styles from "./ProgressBar.module.css";

type Props = {
    widthPercentage: number;
};

const ProgressBar = ({widthPercentage}: Props) => {
    return (
        <div className={styles.progressBarContainer}>
            <div className={styles.progressBar} style={{width: `${widthPercentage}%`}}/>
        </div>
    );
};

export default ProgressBar;