import React, {useContext} from 'react';
import AccountScreen from "./AccountScreen";
import {AppContext, SidebarContext} from "../../services";
import {useAlert} from "react-alert";

const AccountScreenWrapper = () => {
    const {name, email, selectCompany, signOut} = useContext(AppContext);
    const {showSidebar} = useContext(SidebarContext);
    const alert = useAlert();

    const handleSignOut = async () => {
        try {
            await signOut();
        } catch (error) {
            alert.error('An error occurred. Refresh this page or try again later.');
        }
    };

    return (
        <AccountScreen
            name={name}
            email={email}
            onSignOut={handleSignOut}
            onChangePassword={() => showSidebar({type: 'changePassword'})}
            onSwitchPortal={() => selectCompany(null)}
        />
    );
};

export default AccountScreenWrapper;
