import {default as queryString} from "query-string";
import {useContext} from "react";
import {QueryContext} from "../services";
import {useLocation} from "react-router-dom";

export const useSavedQueryParts = () => {
    const location = useLocation();
    const savedQueryString = useContext(QueryContext);
    const parsedQuery = queryString.parse(savedQueryString);

    const buildUrl = (overrideQuery: object) => {
        const usedQuery = {
            ...parsedQuery,
            ...overrideQuery,
        };

        return {
            pathname: location.pathname,
            search: queryString.stringify(usedQuery),
        };
    };

    return {
        parsedQuery,
        buildUrl,
    };
};

export const useQueryParts = () => {
    const location = useLocation();
    const parsedQuery = queryString.parse(location.search);

    const buildUrl = (overrideQuery: object) => {
        const usedQuery = {
            ...parsedQuery,
            ...overrideQuery,
        };

        return {
            pathname: location.pathname,
            search: queryString.stringify(usedQuery),
        };
    };

    return {
        parsedQuery,
        buildUrl,
    };
};
