import React, {useContext, useState} from 'react';
import SidebarForm from "../SidebarForm/SidebarForm";
import styles from "../SidebarChangePassword/SidebarChangePassword.module.css";
import Textfield from "../Textfield/Textfield";
import {useAlert} from "react-alert";
import SidebarFilterContainer from "../SidebarFilters/SidebarFilterContainer";
import {RefreshContext, savePaylinksFileReference, SidebarContext} from "../../services";

type Props = {
    paylinksFileId: number;
    reference: string;
};

const SidebarEditPaylinksFileReference = ({
                                paylinksFileId,
                                reference: defaultReference,
                            }: Props) => {
    const [reference, setReference] = useState(defaultReference || '');
    const alert = useAlert();
    const {hideSidebar} = useContext(SidebarContext);
    const {refresh} = useContext(RefreshContext);

    const handleSubmit = async () => {
        try {
            await savePaylinksFileReference(paylinksFileId, reference);
            hideSidebar();
            alert.success('Your changes have been successfully saved.');
            refresh(`paylinksFile-${paylinksFileId}`);
        } catch (error) {
            return Promise.reject('An error occurred. Refresh this page or try again later.');
        }
    };

    return (
        <SidebarForm buttonLabel="Save" onSubmit={handleSubmit}>
            <div className={styles.field}>
                <SidebarFilterContainer title="Reference">
                    <Textfield
                        value={reference}
                        setValue={setReference}
                        clearable
                        autoFocus
                    />
                </SidebarFilterContainer>
            </div>
        </SidebarForm>
    );
};

export default SidebarEditPaylinksFileReference;