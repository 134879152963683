import React, {Dispatch} from 'react';
import Textfield from "../Textfield/Textfield";
import SidebarFilterRow from "./SidebarFilterRow";
import Select from "../Select/Select";
import {FiltersReducerAction, FiltersType, operatorLabels} from "./SidebarFilters";

type Props<T> = {
    headerConfig: TableHeaderNormalType<T>;
    activeFilters: FiltersType;
    dispatch: Dispatch<FiltersReducerAction>;
    autoFocus: boolean;
};

const SidebarFilterNumber = <T, >({
                                      headerConfig,
                                      activeFilters,
                                      dispatch,
                                      autoFocus,
                                  }: Props<T>) => {
    const handleSetValue = (value: string, key: string) => {
        if (!value) {
            dispatch({
                type: 'setValue',
                key: key,
                value: undefined,
            });
            return;
        }

        const numberValue = parseFloat(value);
        if (isNaN(numberValue)) {
            return;
        }

        dispatch({
            type: 'setValue',
            key: key,
            value: value,
        });
    };

    if (headerConfig.filterableRange) {
        return (
            <SidebarFilterRow
                left={(
                    <Textfield
                        value={activeFilters[`${headerConfig.key}`] as string}
                        setValue={value => handleSetValue(value, `${headerConfig.key}`)}
                        clearable
                        placeholder={headerConfig.title}
                        autoFocus={autoFocus}
                        title="From"
                    />
                )}
                right={(
                    <Textfield
                        value={activeFilters[`${headerConfig.key}_to`] as string}
                        setValue={value => handleSetValue(value, `${headerConfig.key}_to`)}
                        clearable
                        placeholder={headerConfig.title}
                        title="To"
                    />
                )}
            />
        );
    } else if (headerConfig.operators && headerConfig.operators.length > 0) {
        return (
            <SidebarFilterRow
                left={(
                    <Select
                        options={headerConfig.operators.map(operator => ({
                            key: operator,
                            value: operatorLabels[operator],
                        }))}
                        value={activeFilters[`${headerConfig.key}_op`] as string || headerConfig.operators[0]}
                        setValue={(value) => {
                            dispatch({
                                type: 'setValue',
                                key: `${headerConfig.key}_op`,
                                value: value,
                            });
                        }}
                    />
                )}
                right={(
                    <Textfield
                        value={activeFilters[`${headerConfig.key}`] as string}
                        setValue={value => handleSetValue(value, `${headerConfig.key}`)}
                        clearable
                        placeholder={headerConfig.title}
                        autoFocus={autoFocus}
                    />
                )}
            />
        );
    } else {
        return (
            <Textfield
                value={activeFilters[`${headerConfig.key}`] as string}
                setValue={value => handleSetValue(value, `${headerConfig.key}`)}
                clearable
                placeholder={headerConfig.title}
                autoFocus={autoFocus}
            />
        );
    }
};

export default SidebarFilterNumber;
