import React from 'react';
import styles from "./ActionTileBar.module.css";

type Props = {
    children: React.ReactNode;
};

const ActionTileBar = ({children}: Props) => {
    return <div className={styles.barWrapper}>{children}</div>;
};

export default ActionTileBar;
