import React, {useEffect, useRef} from 'react';
import {useAlert} from "react-alert";
import styles from "./HoverCopyTip.module.css";

const HoverCopyTip = () => {
    const alert = useAlert();

    //react-alert doesn't distinguish between onClose from X button
    //or onClose from programmatically .remove()
    //Keep this variable explicitly ourselves
    const autoClosedRef = useRef(false);

    useEffect(
        () => {
            const copyTipSeen = localStorage.getItem('copyTipSeen');
            if (copyTipSeen === 'true') {
                return;
            }

            const message = (
                <div>
                    <div className={styles.title}>Tip</div>
                    <div>Hover to see the full value or click to copy to clipboard.</div>
                </div>
            );

            const alertMessage = alert.info(message, {
                timeout: 0,
                onClose: () => {
                    if (!autoClosedRef.current) {
                        localStorage.setItem('copyTipSeen', 'true');
                    }
                },
            });

            return () => {
                autoClosedRef.current = true;
                alert.remove(alertMessage);
            };
        },
        [alert]
    );

    return <></>;
};

export default HoverCopyTip;
