import React, {MouseEvent, useState} from 'react';
import styles from './Select.module.css';
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

export interface SelectProps {
    options: {
        key: string;
        value: React.ReactNode;
    }[];
    value: string;
    setValue: (value: string) => void;
    border?: boolean;
}

const Select = ({
                    options,
                    value,
                    setValue,
                    border,
                }: SelectProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (e: MouseEvent, newValue: string) => {
        e.stopPropagation();

        if (newValue !== value) {
            setValue(newValue);
        }

        setIsOpen(false);
    };

    return (
        <div
            className={classNames({
                [styles.wrapper]: true,
                [styles.withBorder]: border,
            })}
            onClick={() =>
                setIsOpen(!isOpen)}
        >
            <span className={styles.content}>{options.find(option => option.key === value)?.value}</span>
            <div className={classNames({
                [styles.icon]: true,
                [styles.iconOpen]: isOpen,
                [styles.iconClosed]: !isOpen,
            })}/>
            {isOpen && (
                <div className={styles.list}>
                    {options.map(option => (
                        <div
                            key={option.key}
                            className={classNames({
                                [styles.listItem]: true,
                                [styles.listItemActive]: value === option.key,
                            })}
                            onClick={(e) => handleSelect(e, option.key)}
                        >
                            {option.value}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Select;
