import React from 'react';
import {H1, H3} from "../../components/titles/titles";
import styles from "./DocumentScreen.module.css";
import globalStyles from "../../styles/styles.module.css";
import FieldsPane, {FieldsCol} from "../../components/FieldsPane/FieldsPane";
import Field from "../../components/Field/Field";
import ItemNoResults from "../../components/ItemNoResults/ItemNoResults";
import {Link} from "react-router-dom";
import PaymentStatusAmount from "../../components/PaymentStatusAmount/PaymentStatusAmount";

type Props = {
    document: DocumentType | null | undefined;
};

const DocumentScreen = ({
                            document,
                        }: Props) => {
    return (
        <div>
            <H1>Document</H1>
            <div className={styles.subtitleWrapper}>
                <H3>{document?.documentId}</H3>
            </div>
            {document && <DocumentScreenContents document={document}/>}
            {document === null && <ItemNoResults/>}
        </div>
    );
};

type DocumentScreenContentsProps = {
    document: DocumentType;
};

const DocumentScreenContents = ({document}: DocumentScreenContentsProps) => {
    const {
        id,
        documentType,
        amount,
        communication,
        status,
        dueDate,
        memoDate,
        documentDate,
        title,
        customerId,
        beneficiary,
        channel,
        transactionIds = [],
    } = document;

    return (
        <>
            <div className={styles.primaryFieldsWrapper}>
                <FieldsPane>
                    <FieldsCol>
                        <div className={styles.primaryField}>{documentType}</div>
                        <Field label="Communication" children={communication}/>
                    </FieldsCol>
                    <FieldsCol>
                        <PaymentStatusAmount status={status} amount={amount}/>
                        <div className={globalStyles.detailsRightWrapper}>
                            <div>Due date: {dueDate || '-'}</div>
                            {transactionIds.map(transactionId => (
                                <div key={transactionId}><Link to={`/transactions/${transactionId}`}>Transaction</Link></div>
                            ))}
                        </div>
                    </FieldsCol>
                </FieldsPane>
            </div>
            <FieldsPane>
                <FieldsCol>
                    <Field label="Collection date" children={memoDate}/>
                    <Field label="Document date" children={documentDate}/>
                    <Field label="Description" children={title}/>
                </FieldsCol>
                <FieldsCol>
                    <Field label="Customer Id" children={customerId}/>
                    <Field label="Beneficiary" children={beneficiary}/>
                    <Field label="Channel" children={channel}/>
                </FieldsCol>
            </FieldsPane>
            <div className={globalStyles.primaryID}>
                <span>POM Document Id: </span>{id}
            </div>
        </>
    );
};

export default DocumentScreen;
