import React from 'react';
import ReactTooltip from "react-tooltip";
import styles from './Tooltip.module.css';

const Tooltip = () => {
    return (
        <ReactTooltip
            effect="float"
            className={styles.tooltip}
            arrowColor="white"
        />
    );
};

export default Tooltip;
