import React from 'react';
import styles from './TextAreaSms.module.css';
import Textarea, {TextareaProps} from "./Textarea";
import {count} from "sms-length";

interface Props extends TextareaProps {
    linkNbChars: number;
    documentIdNbChars: number;
    amountNbChars: number;
}

const TextAreaSms = ({
                         value,
                         linkNbChars,
                         documentIdNbChars,
                         amountNbChars,
                         setValue,
                         ...restOfProps
                     }: Props) => {
    const textToCount = (value || '')
        .replace("<Link>", "x".repeat(linkNbChars))
        .replace("<DocumentId>", "x".repeat(documentIdNbChars))
        .replace("<Amount>", "x".repeat(amountNbChars))
    ;
    const smsLength = count(textToCount);

    const handleChange = (value: string) => {
        setValue(value.substring(0, 1000));
    };

    return (
        <div>
            <Textarea
                value={value}
                setValue={handleChange}
                {...restOfProps}
            />
            <div className={styles.counter}>
                <div>Encoding: {smsLength.encoding}</div>
                <div className={styles.spacer}/>
                <div>Length: {smsLength.length}/{smsLength.characterPerMessage * smsLength.messages}</div>
            </div>
            <div className={styles.counter}>
                <div>Characters per message: {smsLength.characterPerMessage}</div>
                <div className={styles.spacer}/>
                <div>Message parts: {smsLength.messages}</div>
            </div>
        </div>
    );
};

export default TextAreaSms;