import React, {useContext, useState} from 'react';
import SidebarForm from "../SidebarForm/SidebarForm";
import styles from "../SidebarChangePassword/SidebarChangePassword.module.css";
import Textfield from "../Textfield/Textfield";
import {RefreshContext, SidebarContext, handleDownloadTemplateSmsFile, AppContext, savePaylinksFile} from "../../services";
import {useAlert} from "react-alert";
import SidebarFilterContainer from "../SidebarFilters/SidebarFilterContainer";
import Radios from "../Radios/Radios";
import moment from "moment";
import TextfieldDate from "../TextfieldDate/TextfieldDate";
import classNames from "classnames";
import FileUpload from "../FileUpload/FileUpload";
import Button from "../Button/Button";

type Props = {
    paylinksFileId: number;
    reference: string;
    documentType: string;
    dueDate?: string;
    expiryDate: string;
    channel: string;
    senderId: number;
};

const SidebarEditPaylinksFile = ({
                                paylinksFileId,
                                reference: defaultReference,
                                documentType: defaultDocumentType,
                                dueDate: defaultDueDate,
                                expiryDate: defaultExpiryDate,
                                channel: defaultChannel,
                                senderId,
                            }: Props) => {
    const [reference, setReference] = useState(defaultReference || '');
    const [documentType, setDocumentType] = useState(defaultDocumentType);
    const [dueDate, setDueDate] = useState(defaultDueDate || '');
    const [expiryDate, setExpiryDate] = useState(defaultExpiryDate);
    const [channel, setChannel] = useState(defaultChannel);
    const [file, setFile] = useState<File>();

    const alert = useAlert();
    const {hideSidebar} = useContext(SidebarContext);
    const {refresh} = useContext(RefreshContext);
    const {companies, currentCompanyId} = useContext(AppContext);
    const company = companies.find(company => company.id === currentCompanyId);

    let usedValueDueDate: Date | undefined = undefined;
    const momentDueDate = moment(dueDate);
    if (momentDueDate.isValid()) {
        usedValueDueDate = momentDueDate.toDate();
    }

    const handleSetDueDate = (value: Date | undefined) => {
        const newValue = value ? moment(value).format('YYYY-MM-DD') : undefined;
        setDueDate(newValue || '');
    };

    let usedValueExpiryDate: Date | undefined = undefined;
    const momentExpiryDate = moment(expiryDate);
    if (momentExpiryDate.isValid()) {
        usedValueExpiryDate = momentExpiryDate.toDate();
    }

    const handleSetExpiryDate = (value: Date | undefined) => {
        const newValue = value ? moment(value).format('YYYY-MM-DD') : undefined;
        setExpiryDate(newValue || '');
    };

    const handleSubmit = async () => {
        if (!documentType) {
            return Promise.reject('Document type is required.');
        }

        if (documentType !== 'Contribution') {
            if (!usedValueDueDate) {
                return Promise.reject('Due date is required.');
            }

            if (momentDueDate.isBefore()) {
                return Promise.reject('Due date must be in the future.');
            }
        }

        if (!expiryDate) {
            return Promise.reject('Expiry date is required.');
        }

        if (documentType !== 'Contribution') {
            if (momentExpiryDate.isBefore(momentDueDate)) {
                return Promise.reject('Expiry date must be after due date.');
            }
        } else {
            if (momentExpiryDate.isBefore()) {
                return Promise.reject('Expiry date must be in the future.');
            }
        }

        try {
            await savePaylinksFile({
                paylinksFileId: paylinksFileId,
                reference,
                documentType,
                dueDate: documentType === 'Contribution' ? '' : dueDate,
                expiryDate,
                channel,
                file,
            });
            hideSidebar();
            alert.success('Your changes have been successfully saved.');
            refresh(`paylinksFile-${paylinksFileId}`);
        } catch (error) {
            return Promise.reject('An error occurred. Refresh this page or try again later.');
        }
    };

    return (
        <SidebarForm buttonLabel="Save" onSubmit={handleSubmit}>
            <div className={styles.field}>
                <SidebarFilterContainer title="Reference">
                    <Textfield
                        value={reference}
                        setValue={setReference}
                        clearable
                        autoFocus
                    />
                </SidebarFilterContainer>
                <SidebarFilterContainer title="The payment links I will create concern the following type of payment">
                    <Radios
                        options={[
                            {key: 'Invoice', value: 'Invoice'},
                            {key: 'Reminder', value: 'Reminder'},
                            {key: 'Contribution', value: 'Contribution'},
                        ]}
                        value={documentType}
                        setValue={setDocumentType}
                    />
                </SidebarFilterContainer>
                {documentType !== 'Contribution' && (
                    <SidebarFilterContainer title="Due date that applies to all payment links">
                        <TextfieldDate
                            title="Due date"
                            hideLabel
                            value={usedValueDueDate}
                            setValue={handleSetDueDate}
                            minimumDate={moment().add(1, 'days').toDate()}
                            maximumDate={moment().add(90, 'days').toDate()}
                        />
                    </SidebarFilterContainer>
                )}
                <SidebarFilterContainer title="The payment links will need to remain valid until">
                    <TextfieldDate
                        title="Expiration date"
                        hideLabel
                        value={usedValueExpiryDate}
                        setValue={handleSetExpiryDate}
                        minimumDate={moment(documentType === 'Contribution' ? undefined : dueDate).add(1, 'days').toDate()}
                        maximumDate={moment().add(90, 'days').toDate()}
                    />
                </SidebarFilterContainer>
                <SidebarFilterContainer title="Choose channel">
                    <Radios
                        options={company!.paylinkSenders.find(s => s.id === senderId)!.channels.map(channel => ({
                            key: channel,
                            value: channel,
                        }))}
                        value={channel}
                        setValue={setChannel}
                    />
                </SidebarFilterContainer>
                <SidebarFilterContainer title="Upload the meta data">
                    <div className={styles.small}>Only upload a new file if you want to replace the meta data.</div>
                    <div className={classNames(styles.field, styles.center)}>
                        <FileUpload
                            file={file}
                            setFile={setFile}
                        />
                    </div>
                    <div className={styles.center}>
                        <Button
                            label="Download template"
                            backgroundColor="white"
                            onClick={() => handleDownloadTemplateSmsFile(currentCompanyId!)}
                        />
                    </div>
                </SidebarFilterContainer>
            </div>
        </SidebarForm>
    );
};

export default SidebarEditPaylinksFile;