import React from 'react';
import styles from './SmsFileContentsTable.module.css';

type Props = {
    file: SmsFileType | PaylinksFileType;
};

const SmsFileContentsTable = ({
                                  file,
                              }: Props) => {
    const {first10rows} = file;
    const [header, ...restOfRows] = first10rows;

    return (
        <div className={styles.wrapper}>
            <table className={styles.table}>
                <thead>
                <tr>
                    {header.map((cell, i) => (
                        <th key={i}>{cell}</th>
                    ))}
                </tr>
                </thead>

                <tbody>
                {restOfRows.map((row, i) => (
                    <tr key={i}>
                        {row.map((cell, i) => (
                            <td key={i}>{cell}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
            {restOfRows.length >= 10 && (
                <span className={styles.footnote}>Only the first 10 rows are shown. Download the original file to view all rows.</span>
            )}
        </div>
    );
};

export default SmsFileContentsTable;