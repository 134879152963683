import React, {useContext} from 'react';
import styles from "./Modal.module.css";
import LoadingButton from "../Button/LoadingButton";
import {cancelSmsFile, RefreshContext} from "../../services";

type Props = {
    onClose: () => void;
    smsFileId: number;
};

const ModalCancelSmsFileContainer = ({
                                         onClose,
                                         smsFileId,
                                     }: Props) => {
    const {refresh} = useContext(RefreshContext);

    const handleSubmit = async () => {
        await cancelSmsFile(smsFileId);
        onClose();
        refresh(`smsFile-${smsFileId}`);
    };

    return (
        <div className={styles.modal}>
            <div className={styles.title}>Are you sure you want to cancel this sms file?</div>
            <div className={styles.body}/>
            <LoadingButton label="Cancel file" onClick={handleSubmit} backgroundColor="red"/>
        </div>
    );
};

export default ModalCancelSmsFileContainer;
