import React from 'react';
import {H2} from "../titles/titles";
import styles from "./ItemNoResults.module.css";

const ItemNoResults = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <H2>No results found</H2>
            </div>
            <div className={styles.description}>
                This item cannot be found. Try again later.
            </div>
        </div>
    );
};

export default ItemNoResults;
