import React, {useContext, useState} from 'react';
import SidebarForm from "../SidebarForm/SidebarForm";
import styles from "../SidebarChangePassword/SidebarChangePassword.module.css";
import Textfield from "../Textfield/Textfield";
import {
    saveSmsFile,
    RefreshContext,
    SidebarContext,
    validSmsContents,
    handleDownloadTemplateSmsFile,
    AppContext,
} from "../../services";
import {useAlert} from "react-alert";
import SidebarFilterContainer from "../SidebarFilters/SidebarFilterContainer";
import Radios from "../Radios/Radios";
import moment from "moment";
import TextfieldDate from "../TextfieldDate/TextfieldDate";
import TextAreaSms from "../Textarea/TextAreaSms";
import classNames from "classnames";
import TextfieldInline from "../TextfieldInline/TextfieldInline";
import FileUpload from "../FileUpload/FileUpload";
import Button from "../Button/Button";

type Props = {
    smsFileId: number;
    reference: string;
    documentType: string;
    dueDate?: string;
    expiryDate: string;
    linkNbChars: number;
    messageNL?: string;
    messageFR?: string;
    messageEN?: string;
    documentIdNbChars: number;
    amountNbChars: number;
};

const SidebarEditSmsFile = ({
                                smsFileId,
                                reference: defaultReference,
                                documentType: defaultDocumentType,
                                dueDate: defaultDueDate,
                                expiryDate: defaultExpiryDate,
                                linkNbChars,
                                messageNL: defaultMessageNL,
                                messageFR: defaultMessageFR,
                                messageEN: defaultMessageEN,
                                documentIdNbChars: defaultDocumentIdNbChars,
                                amountNbChars: defaultAmountNbChars,
                            }: Props) => {
    const [reference, setReference] = useState(defaultReference || '');
    const [documentType, setDocumentType] = useState(defaultDocumentType);
    const [dueDate, setDueDate] = useState(defaultDueDate || '');
    const [expiryDate, setExpiryDate] = useState(defaultExpiryDate);
    const [messageNL, setMessageNL] = useState(defaultMessageNL || '');
    const [messageFR, setMessageFR] = useState(defaultMessageFR || '');
    const [messageEN, setMessageEN] = useState(defaultMessageEN || '');
    const [documentIdNbChars, setDocumentIdNbChars] = useState<number>(defaultDocumentIdNbChars);
    const [amountNbChars, setAmountNbChars] = useState<number>(defaultAmountNbChars);
    const [file, setFile] = useState<File>();

    const alert = useAlert();
    const {hideSidebar} = useContext(SidebarContext);
    const {refresh} = useContext(RefreshContext);
    const {currentCompanyId} = useContext(AppContext);

    let usedValueDueDate: Date | undefined = undefined;
    const momentDueDate = moment(dueDate);
    if (momentDueDate.isValid()) {
        usedValueDueDate = momentDueDate.toDate();
    }

    const handleSetDueDate = (value: Date | undefined) => {
        const newValue = value ? moment(value).format('YYYY-MM-DD') : undefined;
        setDueDate(newValue || '');
    };

    let usedValueExpiryDate: Date | undefined = undefined;
    const momentExpiryDate = moment(expiryDate);
    if (momentExpiryDate.isValid()) {
        usedValueExpiryDate = momentExpiryDate.toDate();
    }

    const handleSetExpiryDate = (value: Date | undefined) => {
        const newValue = value ? moment(value).format('YYYY-MM-DD') : undefined;
        setExpiryDate(newValue || '');
    };

    const handleSubmit = async () => {
        if (!documentType) {
            return Promise.reject('Document type is required.');
        }

        if (documentType !== 'Contribution') {
            if (!usedValueDueDate) {
                return Promise.reject('Due date is required.');
            }

            if (momentDueDate.isBefore()) {
                return Promise.reject('Due date must be in the future.');
            }
        }

        if (!expiryDate) {
            return Promise.reject('Expiry date is required.');
        }

        if (documentType !== 'Contribution') {
            if (momentExpiryDate.isBefore(momentDueDate)) {
                return Promise.reject('Expiry date must be after due date.');
            }
        } else {
            if (momentExpiryDate.isBefore()) {
                return Promise.reject('Expiry date must be in the future.');
            }
        }

        if (!messageNL && !messageFR && !messageEN) {
            return Promise.reject('At least one message is required.');
        }

        if (messageNL && !validSmsContents(messageNL)) {
            return Promise.reject('NL message is not valid.');
        }

        if (messageFR && !validSmsContents(messageFR)) {
            return Promise.reject('FR message is not valid.');
        }

        if (messageEN && !validSmsContents(messageEN)) {
            return Promise.reject('EN message is not valid.');
        }

        try {
            await saveSmsFile({
                smsFileId,
                reference,
                documentType,
                dueDate: documentType === 'Contribution' ? '' : dueDate,
                expiryDate,
                messageNL,
                messageFR,
                messageEN,
                file,
                documentIdNbChars,
                amountNbChars,
            });
            hideSidebar();
            alert.success('Your changes have been successfully saved.');
            refresh(`smsFile-${smsFileId}`);
        } catch (error) {
            return Promise.reject('An error occurred. Refresh this page or try again later.');
        }
    };

    return (
        <SidebarForm buttonLabel="Save" onSubmit={handleSubmit}>
            <div className={styles.field}>
                <SidebarFilterContainer title="Reference">
                    <Textfield
                        value={reference}
                        setValue={setReference}
                        clearable
                        autoFocus
                    />
                </SidebarFilterContainer>
                <SidebarFilterContainer title="The payment messages I will send concern the following type of payment">
                    <Radios
                        options={[
                            {key: 'Invoice', value: 'Invoice'},
                            {key: 'Reminder', value: 'Reminder'},
                            {key: 'Contribution', value: 'Contribution'},
                        ]}
                        value={documentType}
                        setValue={setDocumentType}
                    />
                </SidebarFilterContainer>
                {documentType !== 'Contribution' && (
                    <SidebarFilterContainer title="Due date that applies to all payment invitations">
                        <TextfieldDate
                            title="Due date"
                            hideLabel
                            value={usedValueDueDate}
                            setValue={handleSetDueDate}
                            minimumDate={moment().add(1, 'days').toDate()}
                            maximumDate={moment().add(90, 'days').toDate()}
                        />
                    </SidebarFilterContainer>
                )}
                <SidebarFilterContainer title="The payment links that will appear in the messages will need to remain valid until">
                    <TextfieldDate
                        title="Expiration date"
                        hideLabel
                        value={usedValueExpiryDate}
                        setValue={handleSetExpiryDate}
                        minimumDate={moment(documentType === 'Contribution' ? undefined : dueDate).add(1, 'days').toDate()}
                        maximumDate={moment().add(90, 'days').toDate()}
                    />
                </SidebarFilterContainer>
                <SidebarFilterContainer title="This is the message I want to send">
                    <div className={classNames(styles.textarea, styles.small)}>
                        <div>&lt;Link&gt; must be present and counts for {linkNbChars} characters.</div>
                        <div>
                            <span>&lt;DocumentId&gt; counts for </span>
                            <TextfieldInline
                                type="number"
                                value={String(documentIdNbChars)}
                                setValue={value => setDocumentIdNbChars(Math.max(0, parseInt(value)))}
                            />
                            <span> characters.</span>
                        </div>
                        <div>
                            <span>&lt;Amount&gt; counts for </span>
                            <TextfieldInline
                                type="number"
                                value={String(amountNbChars)}
                                setValue={value => setAmountNbChars(Math.max(0, parseInt(value)))}
                            />
                            <span> characters.</span>
                        </div>
                    </div>
                    <div className={styles.textarea}>
                        <TextAreaSms
                            value={messageNL}
                            setValue={setMessageNL}
                            title="NL (optional)"
                            rows={3}
                            linkNbChars={linkNbChars}
                            amountNbChars={amountNbChars}
                            documentIdNbChars={documentIdNbChars}
                        />
                    </div>
                    <div className={styles.textarea}>
                        <TextAreaSms
                            value={messageFR}
                            setValue={setMessageFR}
                            title="FR (optional)"
                            rows={3}
                            linkNbChars={linkNbChars}
                            amountNbChars={amountNbChars}
                            documentIdNbChars={documentIdNbChars}

                        />
                    </div>
                    <div className={styles.textarea}>
                        <TextAreaSms
                            value={messageEN}
                            setValue={setMessageEN}
                            title="EN (optional)"
                            rows={3}
                            linkNbChars={linkNbChars}
                            amountNbChars={amountNbChars}
                            documentIdNbChars={documentIdNbChars}
                        />
                    </div>
                </SidebarFilterContainer>
                <SidebarFilterContainer title="Upload the meta data">
                    <div className={styles.small}>Only upload a new file if you want to replace the meta data.</div>
                    <div className={classNames(styles.field, styles.center)}>
                        <FileUpload
                            file={file}
                            setFile={setFile}
                        />
                    </div>
                    <div className={styles.center}>
                        <Button
                            label="Download template"
                            backgroundColor="white"
                            onClick={() => handleDownloadTemplateSmsFile(currentCompanyId!)}
                        />
                    </div>
                </SidebarFilterContainer>
            </div>
        </SidebarForm>
    );
};

export default SidebarEditSmsFile;