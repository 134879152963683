import React, {useState} from 'react';
import styles from './Calendar.module.css';
import moment from 'moment';
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

export interface CalendarProps {
    title: string;
    value?: Date;
    setValue: (value: Date) => void;
    minimumDate?: Date;
    maximumDate?: Date;
}

const Calendar = ({
                      title,
                      value,
                      setValue,
                      minimumDate,
                      maximumDate,
                  }: CalendarProps) => {
    const [month, setMonth] = useState(moment(value || new Date()).month());
    const [year, setYear] = useState(moment(value || new Date()).year());

    const goToPreviousMonth = () => {
        setMonth(month === 0 ? 11 : month - 1);
        setYear(month === 0 ? year - 1 : year);
    };

    const goToNextMonth = () => {
        setMonth(month === 11 ? 0 : month + 1);
        setYear(month === 11 ? year + 1 : year);
    };

    const goToPreviousYear = () => {
        setYear(year - 1);
    };

    const goToNextYear = () => {
        setYear(year + 1);
    };

    const weekdayOfFirstDay = moment().set('year', year).set('month', month).set('date', 1).isoWeekday();

    return (
        <div className={styles.wrapper} onClick={e => e.stopPropagation()}>
            <div className={styles.title}>{title}</div>

            <div className={styles.bar}>
                <div className={`${styles.icon} ${styles.iconLeft}`} onClick={goToPreviousMonth}/>
                <div className={styles.barItem}>{moment().set('month', month).format('MMMM')}</div>
                <div className={`${styles.icon} ${styles.iconRight}`} onClick={goToNextMonth}/>
                <div className={styles.spacer}/>
                <div className={`${styles.icon} ${styles.iconLeft}`} onClick={goToPreviousYear}/>
                <div className={styles.barItem}>{year}</div>
                <div className={`${styles.icon} ${styles.iconRight}`} onClick={goToNextYear}/>
            </div>

            <div className={styles.daysHeader}>
                <div className={styles.dayHeader}>M</div>
                <div className={styles.dayHeader}>T</div>
                <div className={styles.dayHeader}>W</div>
                <div className={styles.dayHeader}>T</div>
                <div className={styles.dayHeader}>F</div>
                <div className={styles.dayHeader}>S</div>
                <div className={styles.dayHeader}>S</div>
            </div>

            <div className={styles.days}>
                <div style={{width: (weekdayOfFirstDay - 1) * 42}}/>
                {Array(moment().set('year', year).set('month', month).daysInMonth()).fill(0).map((_, i) => {
                    const dayNb = i + 1;
                    const dayMoment = moment().set('year', year).set('month', month).set('date', dayNb);
                    const weekday = dayMoment.isoWeekday();
                    const disabled = (minimumDate && dayMoment.isBefore(minimumDate, 'day')) || (maximumDate && dayMoment.isAfter(maximumDate, 'day'));

                    return (
                        <div
                            className={classNames({
                                [styles.day]: true,
                                [styles.weekend]: weekday >= 6,
                                [styles.today]: dayMoment.isSame(moment(), 'day'),
                                [styles.disabled]: disabled,
                                [styles.selected]: value && dayMoment.isSame(moment(value), 'day'),
                            })}
                            key={dayNb}
                            children={dayNb}
                            onClick={() => !disabled && setValue(dayMoment.toDate())}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Calendar;
