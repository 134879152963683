import React, {useContext} from 'react';
import styles from './ActiveFilter.module.css';
import {Link} from "react-router-dom";
import moment from "moment";
import {AppContext, formatAmount} from "../../services";

export type ActiveFilterProps<T> = {
    header: TableHeaderNormalType<T>;
    value: string;
    valueTo: string;
    link: {
        pathname: string;
        search: string;
    };
    storageKey: AllowedValuesKey;
};

const ActiveFilter = <T, >({
                               header,
                               value,
                               valueTo,
                               link,
                               storageKey,
                           }: ActiveFilterProps<T>) => {
    const {allowedValues} = useContext(AppContext);

    let formatted = '';

    const filterable = header.filterable;
    switch (filterable) {
        case "text":
            formatted = value;
            break;
        case "date": {
            const parts = [
                (value && moment(value).isValid()) ? moment(value).format('DD/MM/YYYY') : '',
                (valueTo && moment(valueTo).isValid()) ? moment(valueTo).format('DD/MM/YYYY') : '',
            ].filter(x => x);
            formatted = parts.join(' - ');
        }
            break;
        case "number": {
            const parts = [
                value ? formatAmount(parseFloat(value)) : '',
                valueTo ? formatAmount(parseFloat(valueTo)) : '',
            ].filter(x => x);
            formatted = parts.join(' - ');
        }
            break;
        case "checkboxes": {
            const allowedValuesContents = allowedValues[storageKey][header.key as string] || [];

            const values = Array.isArray(value) ? value as string[] : [value];
            const formattedValues = values.map(item => {
                for (let i = 0; i < allowedValuesContents.length; i++) {
                    if (allowedValuesContents[i].key === item) {
                        return allowedValuesContents[i].value;
                    }
                }

                return item;
            });

            formatted = formattedValues.join(', ');
        }
            break;
        case false:
            //Do nothing
            break;
        default:
            //Union Exhaustiveness checking (https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html)
            return assertNever(filterable);
    }

    return (
        <Link className={styles.wrapper} to={link}>
            <div className={styles.text}>{header.title}: {formatted}</div>
            <div className={styles.closeIcon}/>
        </Link>
    );
};

const assertNever = (x: never): null => {
    return null;
};

export default ActiveFilter;
