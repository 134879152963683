import React, {useState} from 'react';
import styles from './TextfieldDate.module.css';
import moment from 'moment';
import Calendar from "../Calendar/Calendar";
import classNames from "classnames";

export interface TextfieldDateProps {
    title: string;
    value?: Date;
    setValue: (value: Date | undefined) => void;
    alignCalendar?: 'left' | 'right';
    hideLabel?: boolean;
    clearable?: boolean;
    border?: boolean;
    minimumDate?: Date;
    maximumDate?: Date;
}

const TextfieldDate = ({
                           title,
                           value,
                           setValue,
                           alignCalendar = 'left',
                           hideLabel = false,
                           clearable = true,
                           border,
                           minimumDate,
                           maximumDate,
                       }: TextfieldDateProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSetValue = (value: Date | undefined) => {
        setValue(value);
        setIsOpen(false);
    };

    return (
        <div>
            {title && !hideLabel && <div className={styles.title}>{title}</div>}
            <div
                className={classNames({
                    [styles.wrapper]: true,
                    [styles.withBorder]: border,
                })}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>
                    {value ? moment(value).format('DD/MM/YYYY') : (
                        <span className={styles.placeholder}>DD/MM/YYYY</span>
                    )}
                </span>
                {value && clearable && (
                    <div
                        className={`${styles.icon} ${styles.iconClear}`}
                        onClick={e => {
                            setValue(undefined);
                            e.stopPropagation();
                        }}
                    />
                )}
                <div className={`${styles.icon} ${styles.iconCalendar}`}/>
                {isOpen && (
                    <>
                        <div className={styles.backdrop}/>
                        <div className={`${styles.calendar} ${alignCalendar === 'left' ? styles.calendarLeft : styles.calendarRight}`}>
                            <Calendar
                                title={title}
                                value={value}
                                setValue={handleSetValue}
                                minimumDate={minimumDate}
                                maximumDate={maximumDate}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default TextfieldDate;
