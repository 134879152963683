import React from 'react';
import styles from "./PaymentStatus.module.css";
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

type Props = {
    status: PaymentStatusType;
};

const statusLabels: { [index in PaymentStatusType]: string; } = {
    paid: 'Paid',
    COMPLETED: 'Paid',

    pending: 'Pending',
    PROCESSING: 'Pending',

    rejected: 'Unpaid',
    FAILED: 'Unpaid',
};

const PaymentStatus = ({
                            status,
                        }: Props) => {
    return (
        <div className={classNames('wrapper', `wrapper-${status}`)}>
            <div className={styles.icon}/>
            <div>{statusLabels[status]}</div>
        </div>
    );
};

export default PaymentStatus;
