import React, {useContext} from 'react';
import {exportDownloadUrl, fetchExports, isTableHeaderNormalType, ModalContext} from "../../services";
import {exportColumns} from "../../components/Table/columns";
import GenericTableScreenWrapper from "../GenericTableScreen/GenericTableScreenWrapper";
import GenericTablePrimaryLink from "../../components/GenericTablePrimaryLink/GenericTablePrimaryLink";

const ExportsScreenWrapper = () => {
    const {showModal} = useContext(ModalContext);

    const renderers: TableRenderers<ExportTableType> = {
        id: (item) => (
            <GenericTablePrimaryLink
                icon="download"
                tag="a"
                to={exportDownloadUrl(item.id)}
                onClick={(e) => {
                    e.preventDefault();

                    showModal({
                        type: 'download',
                        exportId: item.id,
                    });
                }}
            />
        ),
    };

    return (
        <GenericTableScreenWrapper
            title="Exports"
            columns={Object.values(exportColumns).filter(isTableHeaderNormalType)}
            fetchFunction={fetchExports}
            itemKey="id"
            storageKey="exports"
            renderers={renderers}
            refreshKey="exports"
        />
    );
};

export default ExportsScreenWrapper;
