import React from 'react';
import styles from './Alert.module.css';
import {AlertComponentPropsWithStyle} from "react-alert";
import classNamesBind from 'classnames/bind';
import {Provider} from 'react-alert';

const classNames = classNamesBind.bind(styles);

const Alert = ({
                   options,
                   message,
                   close,
               }: AlertComponentPropsWithStyle) => {
    return (
        <div className={classNames(styles.alert, styles[options.type!])}>
            {options.type === 'info' && <div className={styles.closeIcon} onClick={close}/>}
            {message}
        </div>
    );
};

type Props = {
    children: React.ReactNode;
};

export const AlertProvider = ({children}: Props) => {
    return (
        <Provider
            template={Alert}
            position="bottom right"
            timeout={4000}
            containerStyle={{marginBottom: 50}}
        >
            {children}
        </Provider>
    );
};

export default Alert;
