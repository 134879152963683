import React, {useContext} from 'react';
import styles from './Sidebar.module.css';
import classNamesBind from 'classnames/bind';
import SidebarChangePassword from "../SidebarChangePassword/SidebarChangePassword";
import {SidebarContext} from "../../services";
import SidebarFilters from "../SidebarFilters/SidebarFilters";
import SidebarColumns from "../SidebarColumns/SidebarColumns";
import SidebarEditSmsFileReference from "../SidebarEditSmsFile/SidebarEditSmsFileReference";
import SidebarEditSmsFile from "../SidebarEditSmsFile/SidebarEditSmsFile";
import SidebarEditPaylinksFile from "../SidebarEditSmsFile/SidebarEditPaylinksFile";
import SidebarEditPaylinksFileReference from "../SidebarEditSmsFile/SidebarEditPaylinksFileReference";

const classNames = classNamesBind.bind(styles);

export interface SidebarProps {
    visible: boolean;
    contents: SidebarContentsType;
}

const Sidebar = ({
                     visible,
                     contents,
                 }: SidebarProps) => {
    const {hideSidebar} = useContext(SidebarContext);
    let children: React.ReactNode = '';

    switch (contents.type) {
        case "changePassword":
            children = <SidebarChangePassword/>;
            break;
        case "editSmsFile":
            children = (
                <SidebarEditSmsFile
                    smsFileId={contents.smsFileId}
                    reference={contents.reference}
                    documentType={contents.documentType}
                    dueDate={contents.dueDate}
                    expiryDate={contents.expiryDate}
                    linkNbChars={contents.linkNbChars}
                    messageNL={contents.messageNL}
                    messageFR={contents.messageFR}
                    messageEN={contents.messageEN}
                    documentIdNbChars={contents.documentIdNbChars}
                    amountNbChars={contents.amountNbChars}
                />);
            break;
        case "editSmsFileReference":
            children = <SidebarEditSmsFileReference smsFileId={contents.smsFileId} reference={contents.reference}/>;
            break;
        case "editPaylinksFile":
            children = (
                <SidebarEditPaylinksFile
                    paylinksFileId={contents.paylinksFileId}
                    reference={contents.reference}
                    documentType={contents.documentType}
                    dueDate={contents.dueDate}
                    expiryDate={contents.expiryDate}
                    channel={contents.channel}
                    senderId={contents.senderId}
                />);
            break;
        case "editPaylinksFileReference":
            children = <SidebarEditPaylinksFileReference paylinksFileId={contents.paylinksFileId} reference={contents.reference}/>;
            break;
        case "empty":
            //Leave children empty
            break;
        case "tableFilters":
            children = <SidebarFilters visibleFilters={contents.filters} allowedValuesKey={contents.allowedValuesKey}/>;
            break;
        case "tableColumns":
            children = (
                <SidebarColumns
                    defaultHeaders={contents.defaultHeaders}
                    allHeaders={contents.allHeaders}
                    onSubmit={contents.onSubmit}
                />
            );
            break;
        default:
            //Union Exhaustiveness checking (https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html)
            return assertNever(contents);
    }

    return (
        <div>
            {visible && <div className={styles.background} onClick={hideSidebar}/>}
            <div className={classNames(styles.wrapper, {[styles.rendered]: visible})}>
                <div className={styles.close} onClick={hideSidebar}/>

                {children}

            </div>
        </div>
    );
};

const assertNever = (x: never): null => {
    return null;
};

export default Sidebar;
