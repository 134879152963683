import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    AppContext,
    downloadOriginalSmsFile,
    downloadProcessedSmsFile,
    fetchSmsFile,
    ModalContext,
    retrieveFilenameFromHeaders,
    sendFileToBrowser,
    SidebarContext
} from "../../services";
import {useGenericItemLoader} from "../../hooks/useGenericItemLoader";
import SmsFileScreen from "./SmsFileScreen";

const SmsFileScreenWrapper = () => {
    const [smsFile, setSmsFile] = useState<SmsFileType | null>();
    const smsFileInitialDownload = useGenericItemLoader(fetchSmsFile, 'smsFile');
    const {companies, currentCompanyId, userId} = useContext(AppContext);
    const {showModal} = useContext(ModalContext);
    const {showSidebar} = useContext(SidebarContext);
    const timeoutRef = useRef<number>();

    let linkNbChars = 0;
    if (smsFile) {
        const company = companies.find(company => company.id === currentCompanyId)!;
        linkNbChars = company.smsSenders.find(smsSender => smsSender.id === smsFile.senderId)?.linkNbChars || 0;
    }

    //Keep shown smsFile in state, so we can replace it with an updated version later
    useEffect(
        () => {
            setSmsFile(smsFileInitialDownload);
        },
        [smsFileInitialDownload]
    );


    useEffect(
        () => {
            const statusListToRenew: SmsFileStatusType[] = ["NEW", "VALIDATING", "RUNNING"];

            //Skip if we're not in a status that needs renewing
            if (!smsFile?.status || !statusListToRenew.includes(smsFile.status)) return;

            //Fetch data and make sure to set a new smsFile. That will re-trigger this effect
            timeoutRef.current = window.setTimeout(async () => {
                try {
                    const data = await fetchSmsFile(String(smsFile.id), currentCompanyId!);
                    setSmsFile(data);
                } catch (error) {
                    //We don't have new data but reset smsFile so effect is re-run
                    setSmsFile({...smsFile});
                }
            }, 2000);

            //Clear timeout if we navigate away from page while timeout is still running
            return () => {
                clearTimeout(timeoutRef.current);
            };
        },
        [smsFile, currentCompanyId]
    );

    const handleDownloadOriginalFile = !smsFile ? null : async () => {
        const response = await downloadOriginalSmsFile(smsFile.id, currentCompanyId!);

        //fallback filename if no filename from server is received
        const filename = retrieveFilenameFromHeaders(response.headers, `Sms file ${smsFile.id}.xlsx`);

        const blob = await response.blob();
        sendFileToBrowser(filename, blob);
    };

    const handleDownloadProcessedFile = !smsFile?.hasProcessedFile ? null : async () => {
        const response = await downloadProcessedSmsFile(smsFile.id, currentCompanyId!);

        //fallback filename if no filename from server is received
        const filename = retrieveFilenameFromHeaders(response.headers, `Sms file ${smsFile.id}.xlsx`);

        const blob = await response.blob();
        sendFileToBrowser(filename, blob);
    };

    const handleProcessFile = (smsFile?.status !== 'VALIDATED' || userId !== smsFile.userId) ? null : async () => {
        showModal({
            type: 'processSmsFile',
            smsFileId: smsFile.id,
            mailWhenComplete: smsFile?.mailWhenComplete,
        });
    };

    const handleCancel = (!smsFile || smsFile.status === 'DONE' || smsFile.status === 'CANCELLED' || userId !== smsFile.userId) ? null : () => {
        showModal({
            type: 'cancelSmsFile',
            smsFileId: smsFile.id,
        });
    };

    let handleEdit = null;
    if (userId === smsFile?.userId) {
        if (smsFile?.status === "VALIDATED" || smsFile?.status === "ERROR") {
            handleEdit = () => {
                showSidebar({
                    type: 'editSmsFile',
                    smsFileId: smsFile.id,
                    reference: smsFile.reference,
                    documentType: smsFile.documentType,
                    dueDate: smsFile.dueDate,
                    expiryDate: smsFile.expiryDate,
                    linkNbChars: linkNbChars,
                    messageNL: smsFile.messageNL,
                    messageFR: smsFile.messageFR,
                    messageEN: smsFile.messageEN,
                    documentIdNbChars: smsFile.documentIdNbChars,
                    amountNbChars: smsFile.amountNbChars,
                });
            };
        } else if (smsFile?.status === "DONE" || smsFile?.status === "CANCELLED") {
            handleEdit = () => {
                showSidebar({
                    type: 'editSmsFileReference',
                    smsFileId: smsFile.id,
                    reference: smsFile.reference,
                });
            };
        }
    }

    return (
        <SmsFileScreen
            smsFile={smsFile}
            onEdit={handleEdit}
            onDownloadOriginalFile={handleDownloadOriginalFile}
            onDownloadProcessedFile={handleDownloadProcessedFile}
            onProcessFile={handleProcessFile}
            onCancel={handleCancel}
        />
    );
};

export default SmsFileScreenWrapper;
