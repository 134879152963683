import React, {useCallback, useState} from 'react';
import {RefreshContext} from "../../services";

type Props = {
    children: React.ReactNode;
};

const RefreshProvider = ({
                           children,
                       }: Props) => {
    const [refreshRegister, setRefreshRegister] = useState<{[index: string]: Function[]}>({});

    const handleRegisterRefreshKey = useCallback((refreshKey: string, refreshFunc: Function) => {
        setRefreshRegister(refreshRegister => ({
            ...refreshRegister,
            [refreshKey]: [
                ...(refreshRegister[refreshKey] || []),
                refreshFunc,
            ],
        }));
    }, [setRefreshRegister]);

    const handleUnregisterRefreshKey = useCallback((refreshKey: string, refreshFunc: Function) => {
        setRefreshRegister(refreshRegister => ({
            ...refreshRegister,
            [refreshKey]: (refreshRegister[refreshKey] || []).filter(func => func !== refreshFunc),
        }));
    }, [setRefreshRegister]);

    const handleRefresh = useCallback((refreshKey: string) => {
        (refreshRegister[refreshKey] || []).forEach((refreshFunc) => {
            refreshFunc();
        });
    }, [refreshRegister]);

    return (
        <RefreshContext.Provider value={{registerRefreshKey: handleRegisterRefreshKey, unregisterRefreshKey: handleUnregisterRefreshKey, refresh: handleRefresh}}>
            {children}
        </RefreshContext.Provider>
    );
};

export default RefreshProvider;
