import React from 'react';
import styles from './CalendarYear.module.css';
import moment from 'moment';
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

export interface CalendarProps {
    value: Date;
    setValue: (value: Date) => void;
    minDate: Date;
}

const CalendarYear = ({
                          value,
                          setValue,
                          minDate,
                      }: CalendarProps) => {
    const years = [];
    for (let year = moment(minDate).year(); year <= moment().year(); year++) {
        years.push(year);
    }

    return (
        <div className={styles.wrapper} onClick={e => e.stopPropagation()}>
            {years.map(year => {
                const yearMoment = moment().set('year', year);

                return (
                    <div
                        key={year}
                        className={classNames(styles.year, {
                            [styles.today]: yearMoment.isSame(moment(), 'year'),
                            [styles.selected]: yearMoment.isSame(moment(value), 'year'),
                        })}
                        onClick={() => setValue(yearMoment.toDate())}
                    >
                        <span>{year}</span>
                    </div>
                );
            })}
        </div>
    );
};

export default CalendarYear;
