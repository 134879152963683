import React, {useContext, useReducer} from 'react';
import SidebarForm from "../SidebarForm/SidebarForm";
import Checkboxes, {CheckboxOption} from "../Checkboxes/Checkboxes";
import {SidebarContext} from "../../services";
import Button from "../Button/Button";
import styles from "./SidebarColumns.module.css";

type Props<T> = {
    defaultHeaders: TableHeaderNormalType<T>[];
    allHeaders: TableHeaderNormalType<T>[];
    onSubmit: (headers: TableHeaderNormalType<T>[]) => void;
};

type ReducerAction = {
    type: 'setCheckboxOptions';
    value: CheckboxOption[];
} | {
    type: 'setStrings';
    value: string[];
} | {
    type: 'reset';
};

const SidebarColumns = <T, >({
                                 defaultHeaders,
                                 allHeaders,
                                 onSubmit,
                             }: Props<T>) => {
    const reducer = (headers: TableHeaderNormalType<T>[], action: ReducerAction): TableHeaderNormalType<T>[] => {
        //Move order of headers
        if (action.type === 'setCheckboxOptions') {
            return action.value.map(option => headers.find(header => header.key === option.key)!);
        }

        //Enable/disable header
        if (action.type === 'setStrings') {
            return headers.map(header => ({
                ...header,
                visible: action.value.includes(String(header.key)),
            }));
        }

        //Reset
        if (action.type === 'reset') {
            return allHeaders;
        }

        return headers;
    };

    const [headers, dispatch] = useReducer(reducer, defaultHeaders);
    const {hideSidebar} = useContext(SidebarContext);

    const handleSubmit = () => {
        onSubmit(headers);
        hideSidebar();
    };

    const options = headers.map(header => ({
        key: String(header.key),
        value: header.title,
        disabled: header.cannotHideColumn,
    }));

    const value = headers
        .filter(header => header.visible)
        .map(header => String(header.key));

    return (
        <SidebarForm title="Which columns do you want to see?" onSubmit={handleSubmit}>
            <Checkboxes
                options={options}
                setOptions={options => dispatch({type: 'setCheckboxOptions', value: options})}
                value={value}
                setValue={(value) => dispatch({type: 'setStrings', value: value})}
                sortable
            />
            <div className={styles.resetWrapper}>
                <Button
                    backgroundColor="transparent"
                    label="Reset"
                    onClick={() => dispatch({type: 'reset'})}
                />
            </div>
        </SidebarForm>
    );
};

export default SidebarColumns;
