import React, {useState} from 'react';
import styles from './TextfieldMonth.module.css';
import moment from 'moment';
import CalendarMonth from "../CalendarMonth/CalendarMonth";

export interface TextfieldDateProps {
    title: string;
    value: Date;
    setValue: (value: Date | undefined) => void;
    alignCalendar?: 'left' | 'right';
}

const TextfieldMonth = ({
                            title,
                            value,
                            setValue,
                            alignCalendar = 'left',
                        }: TextfieldDateProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSetValue = (value: Date | undefined) => {
        setValue(value);
        setIsOpen(false);
    };

    return (
        <div>
            <div className={styles.wrapper} onClick={() => setIsOpen(!isOpen)}>
                <span>{moment(value).format('MMMM YYYY')}</span>
                <div className={`${styles.icon} ${styles.iconCalendar}`}/>
                {isOpen && (
                    <>
                        <div className={styles.backdrop}/>
                        <div className={`${styles.calendar} ${alignCalendar === 'left' ? styles.calendarLeft : styles.calendarRight}`}>
                            <CalendarMonth
                                title={title}
                                value={value}
                                setValue={handleSetValue}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default TextfieldMonth;
