import React, {useContext, useState} from 'react';
import LoginScreen from "./LoginScreen";
import {AppContext} from "../../services";

const LoginScreenWrapper = () => {
    const {signIn} = useContext(AppContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleLogin = async () => {
        if (loading || !username || !password) {
            return;
        }

        setLoading(true);
        try {
            await signIn(username, password);
        } catch (error) {
            setLoading(false);
            setShowError(true);
        }
    };

    return (
        <LoginScreen
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            onLogin={handleLogin}
            loading={loading}
            showError={showError}
        />
    );
};

export default LoginScreenWrapper;
