import React from 'react';
import styles from './IconButton.module.css';
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

export interface IconButtonProps {
    type: 'columns' | 'search' | 'download' | 'add';
    onClick?: () => void;
}

const IconButton = ({
                        type,
                        onClick,
                    }: IconButtonProps) => {
    return (
        <div
            className={classNames(styles.button, type)}
            onClick={onClick}
        />
    );
};

export default IconButton;
